import { Box, Button, CheckBox, FormField, Image, Paragraph, } from 'grommet';
import styled from 'styled-components';
const InfoContainerBig = styled(Box) `
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;

  @media (max-width: 680px) {
    text-align: center;
  }
`;
export default InfoContainerBig;
export const ImageLogo = styled(Image) `
  max-width: 100%;
`;
export const TitleInfoContainerBig = styled(Paragraph) `
  font-size: 2.2rem;
  margin-bottom: 5%;

  @media (max-width: 680px) {
    font-size: 1.6rem;
    margin-bottom: 3%;
  }
`;
export const TextInfoContainerBig = styled(Paragraph) `
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;

  @media (max-width: 680px) {
    font-size: .9rem;
  }
`;
export const FormContainer = styled(Box) `
  width: 100%;
`;
export const BoxShad = styled(Box) `
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (max-width: 680px) {
    box-shadow: none;
  }
`;
export const FormFieldInput = styled(FormField) `
  width: 90%;

  @media (max-width: 920px) {
    width: 100%
  }
`;
export const ButtonLogin = styled(Button) `
  padding: 1.5% 5%;
  margin-top: 3%;
  color: #fff;
  background-color: #1373AD;
  border: #1373AD;

  @media (max-width: 920px) {
    padding: 1.5% 4%;
    margin-top: 5%;
  }

  @media (max-width: 680px) {
    margin-top: 5%;
    padding: 2% 4%;
  }
`;
export const CheckBoxComponent = styled(CheckBox) `
  margin-top: 5%;
`;
export const TextBottonContainer = styled(Paragraph) `
  font-size: 1rem;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 1%;
  width: 100%;

  @media (max-width: 920px) {
    margin-top: 15%;
  }
`;
export const TinyTextFormContainer = styled(Paragraph) `
  font-size: 1rem;
  text-align: center;
  margin-top: 0;
  width: 100%;
`;
export const BoxFormContainerBig = styled(Box) `
  width: 50%;
  max-height: 800px;
  padding-left: 5%;

  @media (max-width: 920px) {
    width: 100%;
    padding-left: 0;
  }
`;
