import Axios from '../../../../Utils/axios';
const getQuestao = async (chaveGuid) => {
    let questao = {
        Success: true,
    };
    await Axios().get(`Chaves/QuestaoParaResponder/${chaveGuid}`)
        .then((response) => {
        const { data } = response;
        questao = data;
        return questao;
    })
        .catch(() => {
        questao.Success = false;
        return questao;
    });
    return questao;
};
export default getQuestao;
