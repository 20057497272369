import { Box, Button, Grid, Heading, Layer, RadioButtonGroup, } from 'grommet';
import { Save } from 'grommet-icons';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { withTranslation } from 'react-i18next';
import { useStateValue } from '../AppContext/StateProvider';
import saveConfig from './saveConfig';
const ConfigLayer = withTranslation()((props) => {
    const { t } = props;
    const [{ Config, Theme, Language, IsAuthenticated, }, dispatch] = useStateValue();
    const onClose = () => {
        dispatch({
            type: 'changeConfig',
            value: !Config,
        });
    };
    const setTheme = (newTheme) => {
        dispatch({
            type: 'changeTheme',
            value: newTheme,
        });
    };
    const setLanguage = (newLang) => {
        dispatch({
            type: 'changeLanguage',
            value: newLang,
        });
    };
    const setConfig = async () => {
        onClose();
        if (IsAuthenticated) {
            await saveConfig({
                Theme,
                Language,
            });
        }
    };
    return (React.createElement(React.Fragment, null, (Config) && (React.createElement(Layer, { position: "center", onClickOutside: onClose, onEsc: onClose, responsive: false, modal: true },
        React.createElement(Box, { pad: "medium", gap: "small", width: "medium", background: (Theme === 'light') ? 'white' : 'black' },
            React.createElement(Heading, { level: 4, margin: {
                    bottom: 'medium',
                }, fill: true, textAlign: "center" }, t('Header:Config.Title')),
            React.createElement(Grid, { columns: {
                    count: 2,
                    size: 'auto',
                }, gap: "small" },
                React.createElement(Box, null,
                    React.createElement(Heading, { level: 5, margin: {
                            bottom: 'small',
                            top: 'none',
                        } }, t('Header:Config.Theme.Title')),
                    React.createElement(RadioButtonGroup, { name: "Theme", options: [
                            {
                                label: t('Header:Config.Theme.Light'),
                                value: 'light',
                            },
                            {
                                label: t('Header:Config.Theme.Dark'),
                                value: 'dark',
                            },
                        ], value: Theme, onChange: (event) => setTheme(event.target.value) })),
                React.createElement(Box, null,
                    React.createElement(Heading, { level: 5, margin: {
                            bottom: 'small',
                            top: 'none',
                        } }, t('Header:Config.Language.Title')),
                    React.createElement(RadioButtonGroup, { name: "Language", options: [
                            {
                                label: 'English',
                                value: 'ENG',
                            },
                            {
                                label: 'Español',
                                value: 'SPA',
                            },
                            {
                                label: 'Português',
                                value: 'PTBR',
                            },
                        ], value: Language, onChange: (event) => setLanguage(event.target.value) }))),
            React.createElement(Box, { gap: "small", direction: "row", align: "center", justify: "center", pad: { top: 'medium', bottom: 'small' } },
                React.createElement(Button, { label: t('Header:Config.Save.Text'), title: t('Header:Config.Save.Title'), onClick: setConfig, icon: React.createElement(Save, null), primary: true })))))));
});
ConfigLayer.displayName = 'ConfigLayer';
export default ConfigLayer;
