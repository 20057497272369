import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../../404/NotFound';
import Identity from '../../Identity/Identity';
import RecuperarSenha from '../../RecuperarSenha/RecuperarSenha';
import AppWrapper from '../../Template/GrommetWrapper/AppWrapper';
import ConfirmaRecuperacaoDeSenha from '../../RecuperarSenha/ConfirmaRecuperacaoDeSenha';
import CriarUsuario from '../../Identity/CreateUser/CriarUsuario';
import ManageAccount from '../../ManageAccount/ManageAccount';
const IdentityRouter = () => (React.createElement(AppWrapper, null,
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/identity", component: Identity }),
        React.createElement(Route, { exact: true, path: "/identity/criarUsuario/:guid?", component: CriarUsuario }),
        React.createElement(Route, { exact: true, path: "/identity/criarUsuario", component: CriarUsuario }),
        React.createElement(Route, { exact: true, path: "/identity/recuperarSenha", component: RecuperarSenha }),
        React.createElement(Route, { exact: true, path: "/identity/gerenciarConta", component: ManageAccount }),
        React.createElement(Route, { exact: true, path: "/identity/confirmaRecuperacaoDeSenha/:guid", component: ConfirmaRecuperacaoDeSenha }),
        React.createElement(Route, { component: NotFound }))));
IdentityRouter.displayName = 'IdentityRouter';
export default IdentityRouter;
