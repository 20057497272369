import { Box, Button, Image, Paragraph, } from 'grommet';
import styled from 'styled-components';
const ResultBox = styled(Box) `
  padding: 0 1%;
  align-items: center;
  justify-content: center;
  gap: 1%;
  margin: 1rem 0;

  @media (max-width: 680px) {
    max-width: 70%;
    padding: 1rem;
    margin: 0;
  }
`;
export default ResultBox;
export const SubtitleText = styled(Paragraph) `
  font-size: 22px;
  max-width: 100%;
  margin-bottom: 4rem;
  line-height: 32px;

  @media (max-width: 680px) {
    font-size: 18px;
  }
`;
export const RegularText = styled(Paragraph) `
  font-size: 18px;
  max-width: 75%;
  margin-bottom: 2rem;
  line-height: 32px;
`;
export const DividerBox = styled(Box) `
  border-bottom: 1px solid #cccccc;
  margin: 0 20% 2%;
`;
export const ResultTextBox = styled(Box) `
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  line-height: 32px;
`;
export const ResultImage = styled(Image) `
  width: 100%;
  border-radius: 16px;
`;
export const ResultWidthBox = styled(Box) `
  width: 65%;

  @media screen and (max-width: 680px) {
  width: 90%;  
  }

  @media only screen and (min-width: 681px) and (max-width: 959px) {
    width: 80%;
  }
 
  @media only screen and (min-width: 960px) and (max-width: 1280px) {
    width: 70%;
  }
`;
export const ResultVideoBox = styled(Box) `
  width: 40%;

  @media screen and (max-width: 680px) {
  width: 70%;  
  }

  @media only screen and (min-width: 681px) and (max-width: 959px) {
    width: 60%;
  }
 
  @media only screen and (min-width: 960px) and (max-width: 1280px) {
    width: 50%;
  }
`;
export const PromoButton = styled(Button) `
 padding: 1.5rem 3rem;
 border-radius: 8px;
 color: #fff;
 font-weight: 600;
 text-align: center;


 @media (max-width: 680px) {
    padding: 1rem 2rem;
    align-self: flex-start;
 }

 @media only screen and (min-width: 681px) and (max-width: 959px) {
  align-self: flex-start;
  }
`;
