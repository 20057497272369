import React from 'react';
import { Form } from 'grommet';
import { withTranslation } from 'react-i18next';
import setLanguage from './Language';
const FormWrapper = withTranslation()(
// eslint-disable-next-line @typescript-eslint/comma-dangle
(props) => {
    const { children, values, onChange, onSubmit, } = props;
    setLanguage();
    return (React.createElement(Form, { value: values, validate: "blur", messages: {
            required: 'Este campo é obrigatório',
            invalid: 'Valor inválido',
        }, onChange: onChange, onSubmit: onSubmit }, children));
});
FormWrapper.displayName = 'FormWrapper';
export default FormWrapper;
