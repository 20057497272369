import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Heading, Image, ResponsiveContext, Text, } from 'grommet';
import PromoBox, { PromoButton } from './Styles/StyledComponents';
const PromoCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(PromoBox, { background: "#1373AD", fill: "horizontal" },
            React.createElement(Box, { justify: "center" },
                React.createElement(Image, { alignSelf: "center", width: size === 'xsmall' ? '90%' : `${size === 'smallx' ? '60%' : '80%'}`, src: "/public/CardPromoIMG.png" })),
            React.createElement(Box, { direction: "column", width: size === 'xsmall' ? '90%' : `${size === 'smallx' ? '100%' : '50%'}` },
                React.createElement(Heading, { size: "large", margin: "0", level: "3", fill: true, textAlign: size === 'xsmall'
                        ? 'start'
                        : `${size === 'smallx' ? 'center' : 'end'}`, color: "#fff" }, "Quero fazer uma an\u00E1lise completa do meu Perfil,"),
                React.createElement(Text, { textAlign: size === 'xsmall'
                        ? 'start'
                        : `${size === 'smallx' ? 'center' : 'end'}`, color: "#FFF" }, "no \u00E2mbito profissional, pessoal e emocional!"),
                React.createElement(Box, { margin: "2rem 0 0", alignSelf: size === 'xsmall'
                        ? 'start'
                        : `${size === 'smallx' ? 'center' : 'end'}` },
                    React.createElement(Image, { width: size === 'xsmall'
                            ? '60%'
                            : `${size === 'smallx' ? '50%' : '70%'}`, src: "/public/promoCardPrice.png", alignSelf: size === 'xsmall'
                            ? 'start'
                            : `${size === 'smallx' ? 'center' : 'end'}`, alt: "Valor da an\u00E1lise comportamental completa por R$69,90" })),
                React.createElement(Box, { direction: "row", alignSelf: size === 'xsmall'
                        ? 'start'
                        : `${size === 'smallx' ? 'center' : 'end'}`, margin: "2rem 0 0" },
                    React.createElement("a", { href: "mailto:comercial@hextriade.com?subject=Quero comprar uma chave" },
                        React.createElement(PromoButton, { primary: true, color: "status-ok" }, "COMPRE AGORA")))))));
});
PromoCard.displayName = 'PromoCard';
export default PromoCard;
