import React from 'react';
import { Box, Paragraph } from 'grommet';
import { ImageLogo } from '../LoginPage/Styles/StyledComponents';
import SmallBox, { ChartBox, ChartRadarBox, ListBox, } from './Styles/StyledComponents';
import PageWrapper from '../PageWrapper/PageWrapper';
const Dashboard = () => (React.createElement(PageWrapper, null,
    React.createElement(Box, { direction: "row", align: "center", justify: "between", width: "900px" },
        React.createElement(Box, { direction: "row", gap: "6px" },
            React.createElement(Paragraph, { style: { fontWeight: 600 } }, "Analisando:"),
            React.createElement(Paragraph, { color: "#8f8f8f" }, "Alysson Andrade")),
        React.createElement(Box, { direction: "row", gap: "6px" },
            React.createElement(Paragraph, { style: { fontWeight: 600 } }, "Data:"),
            React.createElement(Paragraph, { color: "#8f8f8f" }, "23/03/2023")),
        React.createElement(ImageLogo, { width: "18%", src: "/public/Hex02.png" })),
    React.createElement(Box, { direction: "row", gap: "8px" },
        React.createElement(Box, null,
            React.createElement(SmallBox, { style: { height: '150px', textAlign: 'center' } },
                React.createElement(Paragraph, { margin: "0", size: "10px", style: { fontWeight: 600 } }, "Diferencial como lider"),
                React.createElement(Paragraph, { size: "10px", margin: "5px 5px 0", color: "#8f8f8f" }, "Entregar tudo o que pensa de forma bem objetiva, sendo muito competente e capaz."))),
        React.createElement(Box, null,
            React.createElement(Box, { direction: "row", gap: "8px" },
                React.createElement(SmallBox, null,
                    React.createElement(Paragraph, { size: "08px", margin: "0", style: { fontWeight: 600 } }, "Perfil Dominante"),
                    React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "An\u00E1litico")),
                React.createElement(SmallBox, null,
                    React.createElement(Paragraph, { size: "08px", margin: "0", style: { fontWeight: 600 } }, "Fluxo emocional"),
                    React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Saud\u00E1vel")),
                React.createElement(SmallBox, null,
                    React.createElement(Paragraph, { size: "08px", margin: "0", style: { fontWeight: 600 } }, "Tr\u00EDades Especiais"),
                    React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Objetiva"))),
            React.createElement(Box, { direction: "row", gap: "8px" },
                React.createElement(SmallBox, null,
                    React.createElement(Paragraph, { size: "08px", margin: "0", style: { fontWeight: 600 } }, "Foco"),
                    React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Dominante")),
                React.createElement(SmallBox, null,
                    React.createElement(Paragraph, { size: "08px", margin: "0", style: { fontWeight: 600 } }, "Motiva\u00E7\u00E3o B\u00E1sica"),
                    React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Compreender")),
                React.createElement(SmallBox, null,
                    React.createElement(Paragraph, { size: "08px", margin: "0", style: { fontWeight: 600 } }, "Virtude a desenvolver"),
                    React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Desapego")))),
        React.createElement(Box, null,
            React.createElement(SmallBox, { style: { height: '150px', textAlign: 'start' } },
                React.createElement(Paragraph, { margin: "0", size: "10px", style: { fontWeight: 600 } }, "Tr\u00EDade"),
                React.createElement(Paragraph, { size: "10px", margin: "10px 10px 0", color: "#8f8f8f" }, "- Anal\u00EDtico"),
                React.createElement(Paragraph, { size: "10px", margin: "0 10px", color: "#8f8f8f" }, "- Organizador"),
                React.createElement(Paragraph, { size: "10px", margin: "0 10px", color: "#8f8f8f" }, "- Realizador")))),
    React.createElement(Box, { direction: "row", gap: "8px" },
        React.createElement(ChartRadarBox, null,
            React.createElement(ImageLogo, { width: "80%", src: "/public/radar.png" })),
        React.createElement(ChartRadarBox, null,
            React.createElement(ImageLogo, { width: "80%", src: "/public/radar.png" })),
        React.createElement(ChartRadarBox, null,
            React.createElement(ImageLogo, { width: "80%", src: "/public/radar.png" }))),
    React.createElement(Box, { direction: "row", gap: "8px" },
        React.createElement(ChartBox, null,
            React.createElement(ImageLogo, { width: "80%", src: "/public/progressP.png" })),
        React.createElement(ChartBox, null,
            React.createElement(ImageLogo, { width: "80%", src: "/public/progressP.png" }))),
    React.createElement(Box, { direction: "row", gap: "8px" },
        React.createElement(ChartBox, { width: "590px !important" },
            React.createElement(ImageLogo, { width: "70%", src: "/public/progressG.png" })),
        React.createElement(ChartRadarBox, { height: "220px !important" },
            React.createElement(ImageLogo, { width: "75%", src: "/public/pizza.png" }))),
    React.createElement(ListBox, null,
        React.createElement(Paragraph, { margin: "10px 0 0", size: "10px", style: { fontWeight: 600 } }, "Conflitos de Energia"),
        React.createElement(Box, { direction: "row", justify: "evenly", align: "center", fill: true },
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Objetiva"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Fugaz"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Astuto"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Ponderado"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Ponderado"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Ponderado"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Ponderado"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Ponderado"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Organizado"),
            React.createElement(Paragraph, { size: "08px", margin: "0", color: "#8f8f8f" }, "Taciturno")))));
Dashboard.displayName = 'Dashboard';
export default Dashboard;
