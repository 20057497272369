import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Voc\u00EA como um bom vision\u00E1rio, \u00E9 algu\u00E9m que possui grandes ideias e uma vis\u00E3o ampla para o futuro."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "No entanto, pode ser frustrante quando a perda de foco em seus objetivos o desvia das possibilidades infinitas que seus projetos podem criar. Mas n\u00E3o se preocupe, vision\u00E1rio, a solu\u00E7\u00E3o est\u00E1 ao seu alcance.")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Portanto, vision\u00E1rio..."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Acreditamos em seu potencial de enxergar o pr\u00F3ximo passo e na import\u00E2ncia de criar oportunidades significativas. Queremos ajud\u00E1-lo a utilizar sua vis\u00E3o para moldar um futuro brilhante. Junte-se a n\u00F3s hoje mesmo e descubra como nossa plataforma de recrutamento pode alinhar voc\u00EA com projetos que valorizam seu perfil vision\u00E1rio, ao mesmo tempo em que aprimora suas habilidades de comunica\u00E7\u00E3o e colabora\u00E7\u00E3o. Voc\u00EA merece ser parte integrante e relevante em seus projetos."))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
