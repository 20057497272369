const transferirChavesInitialValues = () => ({
    Email: '',
    ConfirmarEmail: '',
    Nome: '',
    Sobrenome: '',
    Empresa: '',
    Descricao: '',
    Degustacao: 0,
    Pessoal: 0,
    Profissional: 0,
    Avancada: 0,
});
export default transferirChavesInitialValues;
