import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, FormField, ResponsiveContext, TextInput, } from 'grommet';
import { Save } from 'grommet-icons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../FormWrapper/FormWrapper';
import PageTitle from '../PageTitle/PageTitle';
import PageWrapper from '../PageWrapper/PageWrapper';
import recuperarSenhaInitialValues from './Helpers/recuperarSenhaInitialValues';
import RegExpForEmailValidation, { confirmEmailValidation } from '../../Utils/fieldValidation';
import recuperarSenhaSubmit from './Helpers/recuperarSenhaSubmit';
const RecuperarSenha = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const size = useContext(ResponsiveContext);
    const [values, setValues] = useState(recuperarSenhaInitialValues);
    const [submitting, setSubmitting] = useState(false);
    const onChange = (newValue) => { setValues(newValue); };
    const onSubmit = async () => {
        setSubmitting(true);
        await recuperarSenhaSubmit(values)
            .then((data) => {
            if (data.Success) {
                toast.success('Se o email digitado estava correto, você receberá uma mensagem em breve para gerar uma nova senha');
                history.push('/');
            }
            else {
                toast.error('Desculpe, mas houve um erro!');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas houve um erro!');
        });
    };
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Recuperar senha" }),
        React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: onSubmit },
            React.createElement(Box, { direction: size === 'xsmall' ? 'column' : 'row', justify: "evenly", gap: "small", responsive: true },
                React.createElement(FormField, { label: "E-mail", name: "Email", width: size === 'xsmall' ? '100%' : '50%', required: true, validate: {
                        regexp: RegExpForEmailValidation,
                        message: 'E-mail inválido',
                    } },
                    React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Por favor informe o e-mail", title: "Por favor informe o email", placeholder: "email@email.com", maxLength: 95 })),
                React.createElement(FormField, { label: "Confirmar e-mail", name: "ConfirmarEmail", width: size === 'xsmall' ? '100%' : '50%', required: true, validate: [
                        (ConfirmarEmail) => confirmEmailValidation(values.Email, ConfirmarEmail),
                    ] },
                    React.createElement(TextInput, { name: "ConfirmarEmail", type: "email", "aria-label": "Por favor confirme o e-mail", title: "Por favor confirme o email", placeholder: "confirm@email.com", maxLength: 95 }))),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Save, null), type: "submit", label: "Recuperar senha", title: "Clique aqui para recuperar a senha", primary: true, reverse: true, disabled: submitting })))));
});
RecuperarSenha.displayName = 'RecuperarSenha';
export default RecuperarSenha;
