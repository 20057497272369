import { Button, Nav } from 'grommet';
import { Menu } from 'grommet-icons';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { withTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { useStateValue } from '../AppContext/StateProvider';
import HeaderAppBar from './AppBar';
import ConfigLayer from './ConfigLayer';
import IdentityManagerLinks from './IdentityManagerLinks';
import setLanguage from './Language';
import StyledLogoImage from './Styles/HeaderStyles';
const Header = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ IsAuthenticated, Config, Admin }, dispatch] = useStateValue();
    const history = useHistory();
    setLanguage();
    // const changeConfig = (): void => {
    //   dispatch({
    //     type: 'changeConfig',
    //     value: !Config,
    //   });
    // };
    const navigateToIdentity = () => { history.push('/identity'); };
    return (React.createElement(HeaderAppBar, null,
        React.createElement(NavLink, { to: (IsAuthenticated) ? '/cliente' : '/' },
            React.createElement(StyledLogoImage, { src: "/public/Hex02.png", alt: "Performance9x" })),
        React.createElement(Nav, { direction: "row" }, (IsAuthenticated) ? (React.createElement(IdentityManagerLinks, null)) : (React.createElement(Button, { icon: React.createElement(Menu, null), title: "Menu inicial", onClick: navigateToIdentity }))),
        (Config) && React.createElement(ConfigLayer, null)));
});
Header.displayName = 'Header';
export default Header;
