import React from 'react';
import { Box, Button } from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
const BackButton = (props) => {
    const { text, pad = 'small', align = 'start', onClick, } = props;
    return (React.createElement(Box, { fill: "horizontal", pad: (pad) && pad, align: (align) && align },
        React.createElement(Button, { gap: "small", label: text || 'Voltar', icon: React.createElement(FormPreviousLink, null), onClick: onClick, plain: true })));
};
BackButton.displayName = 'BackButton';
export default BackButton;
