import { Anchor, Box, Heading, ResponsiveContext, Text, RadioButtonGroup, } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import QuizMeter from './Components/QuizMeter';
import QuizOption from './Helpers/QuizOptions';
import ConfirmButton from './Styles/StyledComponents';
import PageWrapper from '../PageWrapper/PageWrapper';
const QuizPage = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(PageWrapper, null,
        React.createElement(Box, { direction: "column", align: "center", justify: "center", width: "100%", fill: true },
            React.createElement(Box, { direction: "column", align: "center", justify: "center", margin: "medium", gap: "1em" },
                React.createElement(Heading, { size: size === 'xsmall' ? 'small' : 'medium', level: "3", margin: "large", fill: true }, "- Voc\u00EA regularmente faz novos amigos:"),
                React.createElement(Box, { pad: "medium", alignSelf: "center", alignContent: "center", direction: size === 'xsmall' ? 'column' : 'row', gap: "2em" },
                    React.createElement(Heading, { size: size === 'xsmall' ? 'xsmall' : 'medium', color: "text-xweak", level: "4" }, "Discordo totalmente"),
                    React.createElement(RadioButtonGroup, { name: "quizOptions", gap: "large", direction: size === 'xsmall' ? 'column' : 'row', responsive: true, align: "center", options: QuizOption }),
                    React.createElement(Heading, { size: size === 'xsmall' ? 'xsmall' : 'medium', level: "4", color: "text-xweak" }, "Concordo totalmente")),
                React.createElement(ConfirmButton, { margin: size === 'xsmall' ? 'medium' : 'large', label: "Confirmar", color: "brand" })),
            React.createElement(QuizMeter, { value: 50 })),
        React.createElement(Text, { size: size === 'xsmall' ? 'xsmall' : 'medium', color: "text-xweak" },
            "2022 \u00A9",
            ' ',
            React.createElement(Anchor, { href: "http://www.interdev.me", target: "_blank", label: "Interdev.me", color: "#61CE70" }))));
});
QuizPage.displayName = 'QuizPage';
export default QuizPage;
