import { Box, Paragraph } from 'grommet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const CarrosselItemCard = styled(Box) `
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  align-self: center;

  @media screen and (max-width: 680px) {
    padding: 1rem;
    margin-bottom: 1rem  ;
  }
`;
export default CarrosselItemCard;
export const CarrosselContainer = styled(Box) `
border-radius: 16px;
height: auto;
width: 65%;

  @media screen and (max-width: 680px) {
  width: 90%;  
  margin-top: 2rem;
  }

  @media only screen and (min-width: 681px) and (max-width: 959px) {
    width: 80%;
  }
 
  @media only screen and (min-width: 960px) and (max-width: 1280px) {
    width: 70%;
  }
`;
export const CarrosselText = styled(Paragraph) `
  font-size: 18px;
  max-width: 85%;
  margin-bottom: 2rem;
  line-height: 32px;
`;
export const CarrosselTextLink = styled(Link) `
  color: #00DEDB !important;
  text-decoration: none !important;
  cursor: pointer;
`;
