import { Box } from 'grommet';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useStateValue } from '../Template/AppContext/StateProvider';
import Loading from '../Loading/Loading';
import LoginPage from '../LoginPage/LoginPage';
const Identity = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const [{ IsAuthenticated }] = useStateValue();
    const [loading, setLoading] = useState(true);
    const checkAuth = () => {
        if (IsAuthenticated) {
            history.push('/cliente');
        }
        setLoading(false);
    };
    useEffect(() => {
        checkAuth();
    }, []);
    return (loading) ? (React.createElement(Loading, null)) : (React.createElement(Box, { fill: true, justify: "center", align: "center", alignSelf: "center" },
        React.createElement(LoginPage, null)));
});
Identity.displayName = 'Identity';
export default Identity;
