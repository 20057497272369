import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Voc\u00EA n\u00E3o sente reconhecido como um l\u00EDder natural?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "A frustra\u00E7\u00E3o de n\u00E3o ter alcan\u00E7ado seus objetivos e metas continua a incomod\u00E1-lo? Bem, sua busca por transforma\u00E7\u00E3o e crescimento est\u00E1 prestes a terminar! Se voc\u00EA est\u00E1 pronto para desbloquear seu potencial m\u00E1ximo como l\u00EDder, n\u00E3o perca mais tempo!")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Ent\u00E3o, comandante..."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Se voc\u00EA \u00E9 um l\u00EDder nato, determinado a alcan\u00E7ar grandes objetivos e inspirar outras pessoas a seguir em seus passos, este \u00E9 o momento perfeito para colocar em pr\u00E1tica todo o seu potencial. A hora da conquista chegou!"))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
