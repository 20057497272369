import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Voc\u00EA sente-se irritado quando as coisas n\u00E3o saem como o esperado?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Sonha em alcan\u00E7ar a perfei\u00E7\u00E3o em todas as \u00E1reas da sua vida, tanto profissional quanto pessoal? Prepare-se para ver seus desejos se tornarem realidade! Apresentamos a voc\u00EA a Hextr\u00EDade. A chave para desenvolver suas habilidades como o talentoso organizador que voc\u00EA \u00E9 por natureza.")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Portanto, organizador..."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "N\u00E3o permita que o medo de cometer erros e fracassar o impe\u00E7a de alcan\u00E7ar seu verdadeiro potencial. Junte-se \u00E0 Hextr\u00EDade hoje mesmo e descubra como dominar suas habilidades, atingir suas metas e desfrutar de uma vida equilibrada e bem-sucedida. Voc\u00EA merece se destacar em tudo o que faz, e estamos aqui para apoi\u00E1-lo em cada etapa dessa jornada!"))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
