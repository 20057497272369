import { Box } from 'grommet';
import React from 'react';
import Footer from '../../Home/Components/Footer/Footer';
import Header from '../Header/Header';
import StyledGrid from './Styles/AppWrapperStyles';
const AppWrapper = (props) => {
    const { children } = props;
    return (React.createElement(StyledGrid, { fill: "vertical", rows: ['auto', 'flex', 'auto'], columns: ['auto'], areas: [
            ['header'],
            ['main'],
            ['footer'],
        ] },
        React.createElement(Box, { gridArea: "header", elevation: "medium", fill: "horizontal", align: "center", justify: "center", border: "bottom", style: { boxShadow: 'none' } },
            React.createElement(Header, null)),
        React.createElement(Box, { gridArea: "main", pad: {
                top: 'small',
                bottom: 'small',
            } }, children),
        React.createElement(Box, { gridArea: "footer" },
            React.createElement(Footer, null))));
};
AppWrapper.displayName = 'AppWrapper';
export default AppWrapper;
