const criarContaParaProjetoInitialValues = (conviteGuid) => ({
    Nome: '',
    Sobrenome: '',
    Email: '',
    ConfirmarEmail: '',
    Senha: '',
    ConfirmarSenha: '',
    Telefone: '',
    Empresa: '',
    Cargo: '',
    LeadSource: 'Cadastros Plataforma',
    Formulario: 'Registro Consultor De Projeto',
    Consultor: '',
    Projeto: {
        Guid: conviteGuid,
    },
});
export default criarContaParaProjetoInitialValues;
