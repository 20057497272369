import Axios from '../../../../Utils/axios';
const cancelarTransferencia = async (transferenciaGuid) => {
    let cancelar = {
        Success: true,
    };
    await Axios().get(`Chaves/CancelarTransferenciaDeChaves/${transferenciaGuid}`)
        .then((response) => {
        const { data } = response;
        cancelar = data;
        return cancelar;
    })
        .catch(() => {
        cancelar.Success = false;
        return cancelar;
    });
    return cancelar;
};
export default cancelarTransferencia;
