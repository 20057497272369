import { Box, Button } from 'grommet';
import styled from 'styled-components';
const PromoBox = styled(Box) `
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 16px;

  @media (max-width: 860px) {
    padding: 2rem;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
`;
export default PromoBox;
export const PromoButton = styled(Button) `
 padding: 1.5rem 3rem;
 border-radius: 8px;
 color: #fff;
 font-weight: 600;
 text-align: center;


 @media (max-width: 680px) {
    padding: 1rem 2rem;
    align-self: flex-start;
 }

 @media only screen and (min-width: 681px) and (max-width: 959px) {
  align-self: flex-start;
  }
`;
