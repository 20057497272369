import Axios from '../../../../Utils/axios';
const criarUsuarioSubmit = async (values) => {
    let usuario = {};
    await Axios().post('Identity/CriarUsuario', values)
        .then((response) => {
        const { data } = response;
        usuario = data;
        return usuario;
    })
        .catch(() => {
        usuario.Success = false;
        return usuario;
    });
    return usuario;
};
export default criarUsuarioSubmit;
