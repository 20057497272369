import Axios from '../../../../Utils/axios';
const getIntroducao = async (chaveGuid) => {
    let intro = {
        Success: true,
    };
    await Axios().get(`Chaves/GetIntroducao/${chaveGuid}`)
        .then((response) => {
        const { data } = response;
        intro = data;
        return intro;
    })
        .catch(() => {
        intro.Success = false;
        return intro;
    });
    return intro;
};
export default getIntroducao;
