import { Box, Button, Paragraph, ResponsiveContext, } from 'grommet';
import { LinkNext } from 'grommet-icons';
import React, { useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import Loading from '../../Loading/Loading';
import validaChave from './Helpers/validaChave';
import getIntroducao from './Helpers/getIntroducao';
import verificaIntroducao from './Helpers/verificaIntroducao';
const ResponderQuestionario = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid } = useParams();
    const history = useHistory();
    const size = useContext(ResponsiveContext);
    const [loading, setLoading] = useState(false);
    const [validando, setValidando] = useState(false);
    const [introducao, setIntroducao] = useState({});
    const updateIntroducao = (intro) => {
        setIntroducao(intro);
    };
    const getIntroducaoInfo = async () => {
        setLoading(true);
        await getIntroducao(guid)
            .then((data) => {
            if (data.Success) {
                setIntroducao(data.Object);
                setLoading(false);
            }
            else {
                toast.error('Desculpe, não foi possível carregar a chave');
            }
        })
            .catch(() => {
            toast.error('Desculpe, não foi possível carregar a chave');
        });
    };
    const validaChaveAntesDoQuestionario = async () => {
        setValidando(true);
        setLoading(false);
        await validaChave(guid)
            .then((data) => {
            if (data.Object) {
                history.push(`/chaves/questionario/${guid}`);
            }
            else {
                toast.error('Desculpe, chave não disponível');
                history.push(`/chaves/chaveNaoDisponivel/${guid}`);
            }
        })
            .catch(() => {
            toast.error('Desculpe, chave não disponível');
            history.push(`/chaves/chaveNaoDisponivel/${guid}`);
        });
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { initialData } = window;
            const validacao = {
                introducao: initialData,
                updateIntroducao,
            };
            if (verificaIntroducao(validacao)) {
                setLoading(false);
                return;
            }
        }
        getIntroducaoInfo();
    }, []);
    return (React.createElement(PageWrapper, null, (loading) ? (React.createElement(Loading, null)) : (React.createElement(Box, { width: "100%", pad: "0", gap: "1.5em", align: "center", justify: "center", direction: size === 'xsmall' ? 'column' : 'row' },
        React.createElement(Box, { width: size === 'xsmall' ? '100%' : '40%', pad: "small", align: "center", justify: "center" },
            React.createElement(PageTitle, { level: size === 'xsmall' ? '3' : '2', margin: "1em 0 0", textAlign: size === 'xsmall' ? 'center' : 'start', 
                // eslint-disable-next-line max-len
                text: `Olá ${(introducao.Nome || '')} ${(introducao.Sobrenome || '')}! Seja bem vindo a sua plataforma de Desenvolvimento Profissional.` }),
            React.createElement(Paragraph, { size: "larger", fill: true, textAlign: size === 'xsmall' ? 'center' : 'start' }, "Assista o v\u00EDdeo e entenda como funciona nosso m\u00E9todo e logo ap\u00F3s clique em iniciar avalia\u00E7\u00E3o agora."),
            React.createElement(Button, { alignSelf: size === 'xsmall' ? 'center' : 'start', size: "large", label: "Iniciar avalia\u00E7\u00E3o agora", icon: React.createElement(LinkNext, { color: "brand" }), disabled: validando, onClick: validaChaveAntesDoQuestionario, reverse: true, style: { color: 'brand' } })),
        React.createElement(Box, { align: "center", width: size === 'xsmall' ? '100%' : '60%', pad: "small" },
            React.createElement("iframe", { width: "100%", height: size === 'xsmall' ? '220px' : '350px', src: "https://www.youtube.com/embed/n6sVRpwSK0k", title: "YouTube video player", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true }))))));
});
ResponderQuestionario.displayName = 'ResponderQuestionario';
export default ResponderQuestionario;
