import { Box, DataTable, Paragraph, Text, } from 'grommet';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ActionTransferenciaInfo from './Actions/ActionTransferenciaInfo';
import cancelarTransferencia from '../Helpers/cancelarTransferencia';
import ActionTransferenciaExcluir from './Actions/ActionTransferenciaExcluir';
const TabelaTransferencias = withTranslation()((props) => {
    const { transferencias, atualizarLista } = props;
    const onCancelarTransferencia = async (transferenciaGuid) => {
        await cancelarTransferencia(transferenciaGuid)
            .then((data) => {
            if (data.Success) {
                toast.success('Transferência cancelada com sucesso');
                atualizarLista();
            }
            else {
                toast.error('Desculpe, mas não foi possível cancelar a transferência');
                atualizarLista();
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possível cancelar a transferência');
            atualizarLista();
        });
    };
    const [sort, setSort] = useState({
        property: 'Consultor',
        direction: 'asc',
    });
    const columns = [
        {
            property: 'Consultor',
            header: 'Consultor',
            primary: true,
            sortable: true,
            render: (datum) => (React.createElement(Text, null,
                datum.Nome,
                ' ',
                datum.Sobrenome)),
        },
        {
            property: 'Email',
            header: 'Email',
            primary: true,
            sortable: true,
        },
        {
            property: 'Degustacao',
            header: 'Degustação',
            align: 'center',
            primary: true,
            sortable: true,
            render: (datum) => (React.createElement(Text, null, datum.Degustacao || 0)),
        },
        {
            property: 'Pessoal',
            header: 'Pessoal',
            align: 'center',
            sortable: true,
            render: (datum) => (React.createElement(Text, null, datum.Pessoal || 0)),
        },
        {
            property: 'Profissional',
            header: 'Profissional',
            align: 'center',
            sortable: true,
            render: (datum) => (React.createElement(Text, null, datum.Profissional || 0)),
        },
        {
            property: 'Avancada',
            header: 'Avançada',
            sortable: true,
            align: 'center',
            render: (datum) => (React.createElement(Text, null, datum.Avancada || 0)),
        },
        {
            property: 'Guid',
            header: '',
            sortable: false,
            align: 'center',
            render: (datum) => (React.createElement(Box, { direction: "row", gap: "none" },
                React.createElement(ActionTransferenciaInfo, { transferencia: datum }),
                React.createElement(ActionTransferenciaExcluir, { transferencia: datum, onExcluir: onCancelarTransferencia }))),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { overflow: "auto" },
            React.createElement(DataTable, { columns: columns, data: transferencias, step: 50, background: {
                    header: { color: 'dark-1', opacity: 'strong' },
                    body: ['light-1', 'light-3'],
                    footer: { color: 'dark-1', opacity: 'strong' },
                }, border: { body: 'bottom' }, sortable: true, sort: sort, onSort: setSort, pad: {
                    horizontal: 'small',
                    vertical: 'xsmall',
                }, rowProps: {
                    true: {
                        background: 'dark-brand',
                    },
                } })),
        !transferencias.length && (React.createElement(Box, { pad: "small", align: "center" },
            React.createElement(Paragraph, null, "Voc\u00EA ainda n\u00E3o transmitiu nenhuma li\u00E7enca.")))));
});
TabelaTransferencias.displayName = 'TabelaTransferencias';
export default TabelaTransferencias;
