const QuizOption = [
    { label: '', value: '1' },
    { label: '', value: '2' },
    { label: '', value: '3' },
    { label: '', value: '4' },
    { label: '', value: '5' },
    { label: '', value: '6' },
    { label: '', value: '7' },
];
export default QuizOption;
