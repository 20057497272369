import Axios from '../../../../Utils/axios';
const getTransferencias = async () => {
    let transferencias = {
        Success: true,
    };
    await Axios().get('Chaves/ListarTransferencias')
        .then((response) => {
        const { data } = response;
        transferencias = data;
        return transferencias;
    })
        .catch(() => {
        transferencias.Success = false;
        return transferencias;
    });
    return transferencias;
};
export default getTransferencias;
