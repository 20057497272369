import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Voc\u00EA j\u00E1 sentiu que se doa demais aos outros e nem sempre recebe o reconhecimento que acredita merecer?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Sente que possui uma grande empatia pelas pessoas, mas muitas vezes n\u00E3o recebe a reciprocidade que esperava, o que acaba impedindo o reconhecimento que voc\u00EA merece? Se voc\u00EA respondeu sim, saiba que n\u00E3o est\u00E1 sozinho. Existe uma solu\u00E7\u00E3o perfeita para voc\u00EA! A Hextr\u00EDade tem a chave para transformar essa situa\u00E7\u00E3o.")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Chegou a hora de transformar sua dedica\u00E7\u00E3o em resultados concretos."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Junte-se \u00E0 Hextr\u00EDade hoje mesmo e descubra como alcan\u00E7ar o reconhecimento merecido por sua empatia e generosidade. Voc\u00EA merece ser valorizado e estamos aqui para apoi\u00E1-lo nessa jornada de crescimento e sucesso."))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
