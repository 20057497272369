import { Box } from 'grommet';
import styled from 'styled-components';
const SmallBox = styled(Box) `
  width: 170px;
  margin-top: 10px;
  height: 70px;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #1373ad;
  color: #1373ad;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;
export default SmallBox;
export const ChartBox = styled(Box) `
  width: 440px;
  margin-top: 10px;
  height: 220px;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #1373ad;
  color: #1373ad;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;
export const ChartRadarBox = styled(Box) `
  width: 290px;
  margin-top: 10px;
  height: 180px;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #1373ad;
  color: #1373ad;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;
export const ListBox = styled(Box) `
  width: 890px;
  margin-top: 10px;
  height: 80px;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #1373ad;
  color: #1373ad;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;
