import { Grid, Grommet } from 'grommet';
import styled from 'styled-components';
const StyledGrid = styled(Grid) `
  min-height: 100vh;
`;
export const StyledGrommet = styled(Grommet) `
  display: flex;
  min-heigth: 100vh;
`;
export default StyledGrid;
