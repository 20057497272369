import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, FormField, TextInput, } from 'grommet';
import { useHistory } from 'react-router-dom';
import { Login } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageTitle from '../PageTitle/PageTitle';
import PageWrapper from '../PageWrapper/PageWrapper';
import manageAccountInitialValues from './Helpers/manageAccountInitialValues';
import manageAccountSubmit from './Helpers/manageAccountSubmit';
import FormWrapper from '../FormWrapper/FormWrapper';
import RegExpForEmailValidation, { confirmEmailValidation } from '../../Utils/fieldValidation';
import { useStateValue } from '../Template/AppContext/StateProvider';
const ManageAccount = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const [{ Nome, Sobrenome, Email }, dispatch] = useStateValue();
    const [values, setValues] = useState(manageAccountInitialValues({ Nome, Sobrenome, Email }));
    const [submitting, setSubmitting] = useState(false);
    const onChange = (newValues) => { setValues(newValues); };
    const onSubmit = async () => {
        setSubmitting(true);
        await manageAccountSubmit(values)
            .then((data) => {
            if (data.Success) {
                toast.success('Dados atualizados com sucesso');
                dispatch({
                    type: 'changeNome',
                    value: '',
                });
                dispatch({
                    type: 'changeSobrenome',
                    value: '',
                });
                dispatch({
                    type: 'changeEmail',
                    value: '',
                });
                dispatch({
                    type: 'changeAuth',
                    value: false,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: false,
                });
                dispatch({
                    type: 'changeValidated',
                    value: false,
                });
                history.push('/');
            }
            else {
                toast.error('Desculpe, mas não foi possível salvar os dados');
                setSubmitting(false);
            }
        })
            .catch((response) => {
            if (response.Code === '50500') {
                toast.error('Email já utilizado no sistema');
                setSubmitting(false);
                return;
            }
            toast.error('Desculpe, mas não foi possível salvar os dados');
            setSubmitting(false);
        });
    };
    const validarSenha = (senha) => {
        if (senha.length < 6) {
            return 'Senha deve ter ao menos seis dígitos';
        }
        return undefined;
    };
    const validarConfirmarSenha = (confirmar) => {
        if (confirmar !== values.Senha) {
            return 'Senha e Confirmar senha deves ser iguais';
        }
        return undefined;
    };
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Gerenciar conta" }),
        React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: onSubmit },
            React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
                React.createElement(FormField, { label: "Nome", name: "Nome", width: "50%", required: true },
                    React.createElement(TextInput, { name: "Nome", "aria-label": "Por favor informe o nome", title: "Por favor informe o nome", placeholder: "Nome", maxLength: 95 })),
                React.createElement(FormField, { label: "Sobrenome", name: "Sobrenome", width: "50%", required: true },
                    React.createElement(TextInput, { name: "Sobrenome", "aria-label": "Por favor confirme o sobrenome", title: "Por favor confirme o sobrenome", placeholder: "Sobrenome", maxLength: 95 }))),
            React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
                React.createElement(FormField, { label: "E-mail", name: "Email", width: "50%", required: true, validate: {
                        regexp: RegExpForEmailValidation,
                        message: 'E-mail inválido',
                    } },
                    React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Por favor informe o e-mail", title: "Por favor informe o email", placeholder: "email@email.com", maxLength: 95 })),
                React.createElement(FormField, { label: "Confirmar e-mail", name: "ConfirmarEmail", width: "50%", required: true, validate: [
                        (ConfirmarEmail) => confirmEmailValidation(values.Email, ConfirmarEmail),
                    ] },
                    React.createElement(TextInput, { name: "ConfirmarEmail", type: "email", "aria-label": "Por favor confirme o e-mail", title: "Por favor confirme o email", placeholder: "confirm@email.com", maxLength: 95 }))),
            React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
                React.createElement(FormField, { label: "Senha", name: "Senha", width: "50%", required: true, validate: [
                        (senha) => validarSenha(senha),
                    ] },
                    React.createElement(TextInput, { name: "Senha", type: "password", "aria-label": "Por favor informe a senha", title: "Por favor informe a senha", placeholder: "******" })),
                React.createElement(FormField, { label: "Confirmar senha", name: "ConfirmarSenha", width: "50%", required: true, validate: [
                        (validar) => validarConfirmarSenha(validar),
                    ] },
                    React.createElement(TextInput, { name: "ConfirmarSenha", type: "password", "aria-label": "Por favor confirme a senha", title: "Por favor confirme a senha", placeholder: "******" }))),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Login, null), type: "submit", label: "Atualizar conta", title: "Clique aqui para atualizar a conta", primary: true, reverse: true, disabled: submitting })))));
});
ManageAccount.displayName = 'ManageAccount';
export default ManageAccount;
