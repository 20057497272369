import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../../../../PageWrapper/PageWrapper';
import PageTitle from '../../../../../PageTitle/PageTitle';
import Loading from '../../../../../Loading/Loading';
import getTransferenciaInfoParaRecebimento from '../../../Helpers/getTransferenciaInfoParaRecebimento';
import CriarUsuarioForm from '../../../../../Identity/CreateUser/Components/CriarUsuarioForm';
import criarContaDeConsultorInitialValues from './Helpers/criarContaDeConsultorInitialValues';
import criarContaDeConsultorSubmit from './Helpers/criarContaDeConsultorSubmit';
const CriarContaDeConsultor = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid } = useParams();
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({});
    const getTransferenciaInfo = async () => {
        setLoading(true);
        await getTransferenciaInfoParaRecebimento(guid)
            .then((data) => {
            if (data.Success) {
                setValues(data.Object);
                setLoading(false);
            }
            else {
                toast.error('Desculpe, chave não encontrada');
            }
        })
            .catch(() => {
            toast.error('Desculpe, chave não encontrada');
        });
    };
    useEffect(() => {
        getTransferenciaInfo();
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Criar conta de consultor" }),
        (loading) ? (React.createElement(Loading, null)) : (React.createElement(CriarUsuarioForm, { initialValues: criarContaDeConsultorInitialValues(guid, values.Email, values.Nome, values.Sobrenome), onSubmit: criarContaDeConsultorSubmit }))));
});
CriarContaDeConsultor.displayName = 'CriarContaDeConsultor';
export default CriarContaDeConsultor;
