const verificaIntroducao = (dados) => {
    const { introducao, updateIntroducao } = dados;
    let resposta = true;
    if (introducao.Nome && introducao.Nome.length > 2) {
        resposta = true;
        updateIntroducao(introducao);
    }
    else {
        resposta = false;
    }
    return resposta;
};
export default verificaIntroducao;
