import { Anchor, Box, ResponsiveContext, TextInput, } from 'grommet';
import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormWrapper from '../FormWrapper/FormWrapper';
import initialValues from '../Identity/Login/initialValues';
import onSubmit from '../Identity/Login/onSubmit';
import { useStateValue } from '../Template/AppContext/StateProvider';
import InfoContainerBig, { FormContainer, ButtonLogin, CheckBoxComponent, FormFieldInput, TextBottonContainer, TextInfoContainerBig, TinyTextFormContainer, TitleInfoContainerBig, ImageLogo, BoxShad, } from './Styles/StyledComponents';
import PageWrapper from '../PageWrapper/PageWrapper';
const LoginPage = () => {
    const history = useHistory();
    const [, dispatch] = useStateValue();
    const size = useContext(ResponsiveContext);
    const [values, setValues] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const onChange = (value) => {
        setValues(value);
    };
    const submit = async (event) => {
        const { value } = event;
        setLoading(true);
        await onSubmit(value)
            .then((data) => {
            if (data.Success) {
                toast.success('Login efetuado com sucesso.');
                const user = data.Object;
                dispatch({
                    type: 'changeNome',
                    value: user.Nome,
                });
                dispatch({
                    type: 'changeSobrenome',
                    value: user.Sobrenome,
                });
                dispatch({
                    type: 'changeEmail',
                    value: user.Email,
                });
                dispatch({
                    type: 'changeAuth',
                    value: true,
                });
                dispatch({
                    type: 'changeAdmin',
                    value: true,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: true,
                });
                history.push('/cliente');
            }
            else {
                setLoading(false);
                toast.error('Ops! Usuário ou senha incorretos.');
            }
        })
            .catch(() => {
            setLoading(false);
            toast.error('Ops! Usuário ou senha incorretos.');
        });
    };
    return (React.createElement(PageWrapper, null,
        React.createElement(Box, { fill: "horizontal", justify: "center", align: "center", direction: size === 'xsmall' ? 'column' : 'row', gap: size === 'xsmall' ? '0' : '1em' },
            React.createElement(Box, { align: "center", justify: "center", width: size === 'xsmall' ? '100%' : '50%', margin: size === 'xsmall' ? '5rem 0 0' : '0', pad: size === 'xsmall' ? '1em' : '0' },
                React.createElement(ImageLogo, { width: size === 'xsmall' ? '85%' : '80%', margin: "0 0 0 -1em", alignSelf: "center", src: "/public/Hex02.png" }),
                React.createElement(InfoContainerBig, null,
                    React.createElement(TitleInfoContainerBig, { color: "#1373AD" }, "Seja bem vindo(a)!"),
                    React.createElement(TextInfoContainerBig, null, "Digite seu dados para acessar sua conta."))),
            React.createElement(BoxShad, { pad: size === 'xsmall' ? '0 .5em' : '1em', align: "center", justify: "center", style: { boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;' } },
                React.createElement(FormContainer, null,
                    React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: submit },
                        React.createElement(FormFieldInput, { label: "E-mail", name: "Email", width: "100%", required: true, validate: {
                                // eslint-disable-next-line max-len
                                regexp: 
                                // eslint-disable-next-line max-len
                                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                message: 'Digite um email válido',
                            } },
                            React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Digite seu e-mail", title: "Digite seu e-mail" })),
                        React.createElement(FormFieldInput, { label: "Senha", width: "100%", name: "Senha", required: true, validate: [
                                (Password) => {
                                    if (Password.length < 6 || Password.length > 15) {
                                        return 'Senha inválida';
                                    }
                                    return undefined;
                                },
                            ] },
                            React.createElement(TextInput, { name: "Senha", type: "password", "aria-label": "Digite sua senha", title: "Digite sua senha" })),
                        React.createElement(CheckBoxComponent, { name: "PermanecerConectado", label: "Permanecer conectado", title: "Login:KeepConnected:Title", a11yTitle: "Login:KeepConnected:Title" }),
                        React.createElement(Box, { direction: "row", justify: "between" },
                            React.createElement(ButtonLogin, { type: "submit", label: "Acessar agora", title: "Login:Submit:Title", primary: true, reverse: true, disabled: loading })))),
                React.createElement(TextBottonContainer, { color: "text-xweak", fill: true },
                    "Ainda n\u00E3o possui uma conta?",
                    ' ',
                    React.createElement(Link, { to: "/identity/criarUsuario" }, " Registre-se. ")),
                React.createElement(TinyTextFormContainer, { color: "text-xweak", fill: true },
                    "Esqueceu sua senha?",
                    ' ',
                    React.createElement(Link, { to: "/identity/recuperarSenha" }, "Clique aqui.")),
                React.createElement(TinyTextFormContainer, { color: "text-xweak", fill: true },
                    "2023 \u00A9",
                    ' ',
                    React.createElement(Anchor, { href: "http://www.interdev.me", target: "_blank", label: "Interdev.me", color: "#1373AD" }))))));
};
LoginPage.displayName = 'LoginPage';
export default LoginPage;
