import { Box, DataTable, Heading, Image, ResponsiveContext, } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as LinkIcon, Trash } from 'grommet-icons';
import PageWrapper from '../PageWrapper/PageWrapper';
import InviteBox, { InviteBoxButton, SubtitleText, } from './Styles/StyledComponents';
import BackButton from '../BackButton/BackButton';
const Hexpass = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    const columns = [
        {
            property: 'Consultor',
            header: 'Consultor',
            primary: true,
        },
        {
            property: 'Leitor',
            header: 'Somente leitura?',
            size: 'small',
        },
        {
            property: 'Admin',
            header: 'Admin?',
            size: 'small',
        },
        {
            property: 'DeletIcon',
            header: 'Excluir',
            size: 'small',
        },
    ];
    const data = [
        {
            Consultor: 'Ricardo Amorim Gaefke',
            Leitor: 'Não',
            Admin: 'Sim',
            DeletIcon: (React.createElement(Trash, { color: "red", size: size === 'xsmall' ? '18px' : 'medium' })),
        },
        {
            Consultor: 'Bruno Amorim Gaefke',
            Leitor: 'Não',
            Admin: 'Sim',
            DeletIcon: (React.createElement(Trash, { color: "red", size: size === 'xsmall' ? '18px' : 'medium' })),
        },
        {
            Consultor: 'Paulo Roberto Gaefke',
            Leitor: 'Sim',
            Admin: 'Não',
            DeletIcon: (React.createElement(Trash, { color: "red", size: size === 'xsmall' ? '18px' : 'medium' })),
        },
    ];
    return (React.createElement(PageWrapper, null,
        React.createElement(BackButton, null),
        React.createElement(Box, { align: "center", justify: "center", direction: "column", style: { textAlign: 'center' } },
            React.createElement(Heading, { size: size === 'xsmall' ? 'medium' : 'large', level: "3", fill: true, color: "#8F8F8F", margin: "1rem" }, "HEXPASS"),
            React.createElement(SubtitleText, null, "Selecione o tipo de convite que voc\u00EA deseja enviar:")),
        React.createElement(Box, { direction: size === 'xsmall' ? 'column' : 'row', gap: size === 'xsmall' ? '2rem' : '5rem', margin: "2rem 0" },
            React.createElement(InviteBox, { align: "center", justify: "center" },
                React.createElement(Image, { margin: size === 'xsmall' ? '1rem' : '2rem', width: size === 'xsmall' ? '45%' : '75%', src: "/public/Colaborar.png" }),
                React.createElement(InviteBoxButton, { reverse: true, icon: React.createElement(LinkIcon, { size: size === 'xsmall' ? '18px' : 'medium' }), label: "Colaboradores" })),
            React.createElement(InviteBox, { align: "center", justify: "center" },
                React.createElement(Image, { margin: size === 'xsmall' ? '1rem' : '2rem', width: size === 'xsmall' ? '45%' : '75%', src: "/public/administrador.png" }),
                React.createElement(InviteBoxButton, { reverse: true, icon: React.createElement(LinkIcon, { size: size === 'xsmall' ? '18px' : 'medium' }), label: "Administrador" })),
            React.createElement(InviteBox, { align: "center", justify: "center" },
                React.createElement(Image, { margin: size === 'xsmall' ? '1rem' : '2rem', width: size === 'xsmall' ? '45%' : '75%', src: "/public/consultor.png" }),
                React.createElement(InviteBoxButton, { reverse: true, icon: React.createElement(LinkIcon, { size: size === 'xsmall' ? '18px' : 'medium' }), label: "Consultor" }))),
        React.createElement(Box, { align: "center", justify: "center", pad: "large", fill: true, overflow: "auto" },
            React.createElement(DataTable, { columns: columns, data: data, step: 10, pad: { horizontal: 'small', vertical: 'xsmall' }, background: {
                    header: { color: 'dark-1', opacity: 'strong' },
                    body: ['light-1', 'light-3'],
                    footer: { color: 'dark-1', opacity: 'strong' },
                } }))));
});
Hexpass.displayName = 'Hexpass';
export default Hexpass;
