import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Voc\u00EA j\u00E1 sentiu que seus projetos nem sempre geram os resultados esperados?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Acredita que quando algo d\u00E1 errado, tanto em sua vida pessoal quanto profissional, voc\u00EA se esfor\u00E7a excessivamente e acaba se tornando um workaholic, cobrando de si mesmo a obriga\u00E7\u00E3o de alcan\u00E7ar resultados a qualquer custo?")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Por isso, realizador..."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "N\u00E3o deixe que o medo de obter resultados insatisfat\u00F3rios o impe\u00E7a de alcan\u00E7ar seus objetivos. Voc\u00EA merece realizar e fazer a diferen\u00E7a na resolu\u00E7\u00E3o de seus projetos. Estamos aqui para apoi\u00E1-lo em sua jornada para se tornar um profissional realizado e pr\u00F3spero em todas as suas demandas."))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
