import { Box, Heading, RadioButtonGroup, ResponsiveContext, } from 'grommet';
import React, { useContext, useState, useEffect } from 'react';
import { Radial, RadialSelected, } from 'grommet-icons';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import PageWrapper from '../../PageWrapper/PageWrapper';
import QuizMeter from '../../QuizPage/Components/QuizMeter';
import QuizOption from '../../QuizPage/Helpers/QuizOptions';
import ConfirmButton from '../../QuizPage/Styles/StyledComponents';
import FormWrapper from '../../FormWrapper/FormWrapper';
import getQuestao from './Helpers/getQuestao';
import Loading from '../../Loading/Loading';
import responderQuestao from './Helpers/responderQuestao';
import getConsultorEPerfilDominte from './Helpers/getConsultorEPerfilDominte';
const Questionario = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    const { guid } = useParams();
    const history = useHistory();
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(true);
    const onFormChange = (newValues) => { setValues(newValues); };
    const finalizaQuestionario = async () => {
        await getConsultorEPerfilDominte(guid)
            .then((data) => {
            if (data.Success) {
                if (data.Object.CriadoPor.Id && data.Object.CriadoPor.Id === 1818) {
                    let url = '';
                    switch (data.Object.Dominante) {
                        case 1:
                            url = '/organizador';
                            break;
                        case 2:
                            url = '/prestativo';
                            break;
                        case 3:
                            url = '/realizador';
                            break;
                        case 4:
                            url = '/criativo';
                            break;
                        case 5:
                            url = '/analitico';
                            break;
                        case 6:
                            url = '/planejador';
                            break;
                        case 7:
                            url = '/visionario';
                            break;
                        case 8:
                            url = '/comandante';
                            break;
                        case 9:
                            url = '/mediador';
                            break;
                        default:
                            break;
                    }
                    history.push(url);
                }
                else {
                    history.push(`/chaves/finalizarQuestionario/${guid}`);
                }
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas houve um erro');
        });
    };
    const getData = async () => {
        setLoading(true);
        await getQuestao(guid)
            .then((data) => {
            if (data.Success) {
                if (data.Object.TotalRespondidas && data.Object.TotalRespondidas === 99) {
                    finalizaQuestionario();
                    return;
                }
                setValues({
                    Guid: data.Object.Guid,
                    Texto: data.Object.Texto,
                    Resposta: 0,
                    TotalRespondidas: data.Object.TotalRespondidas || 0,
                });
                setLoading(false);
            }
            else {
                toast.error('Desculpe, mas houve um erro');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas houve um erro');
        });
    };
    const onFormSubmit = async (event) => {
        const { value } = event;
        if (value.Resposta === 0) {
            toast.error('Por favor selecione uma opção');
            return;
        }
        setLoading(true);
        await responderQuestao(values)
            .then((data) => {
            if (data.Success) {
                getData();
            }
            else {
                toast.error('Desculpe, mas não foi possível registrar a resposta');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possível registrar a resposta');
        });
    };
    useEffect(() => {
        getData();
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement(Box, { direction: "column", align: "center", justify: "center", margin: "medium" },
            React.createElement(FormWrapper, { values: values, onChange: onFormChange, onSubmit: onFormSubmit }, (loading) ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
                React.createElement(Heading, { size: size === 'xsmall' ? 'small' : 'medium', level: "3", margin: "large", fill: true },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: values.Texto } })),
                React.createElement(Box, { pad: "medium", gap: (size === 'xsmall') ? 'small' : 'medium', justify: "center", align: "center", fill: "horizontal", direction: (size === 'xsmall') ? 'column' : 'row' },
                    React.createElement(Heading, { size: size === 'xsmall' ? 'xsmall' : 'medium', color: "text-xweak", level: "4" }, "Discordo totalmente"),
                    React.createElement(RadioButtonGroup, { name: "Resposta", gap: (size === 'small') ? 'xsmall' : 'medium', direction: (size === 'xsmall') ? 'column' : 'row', responsive: true, align: "center", options: QuizOption }, 
                    // @ts-ignore
                    (option, { checked }) => {
                        const RadioIcon = (checked) ? RadialSelected : Radial;
                        let iconSize = '20';
                        let iconColor = 'grey';
                        if (option.value === '1') {
                            iconSize = '40';
                            iconColor = 'brand';
                        }
                        if (option.value === '2') {
                            iconSize = '30';
                            iconColor = 'brand';
                        }
                        if (option.value === '3') {
                            iconSize = '23';
                            iconColor = 'brand';
                        }
                        if (option.value === '7') {
                            iconSize = '40';
                            iconColor = '#61CE70';
                        }
                        if (option.value === '6') {
                            iconSize = '30';
                            iconColor = '#61CE70';
                        }
                        if (option.value === '5') {
                            iconSize = '23';
                            iconColor = '#61CE70';
                        }
                        return (React.createElement(RadioIcon, { size: iconSize, color: iconColor }));
                    }),
                    React.createElement(Heading, { size: size === 'xsmall' ? 'xsmall' : 'medium', level: "4", color: "text-xweak", textAlign: "end", margin: size === 'smallx' ? '0 0 0 50px' : '0' }, "Concordo totalmente")),
                React.createElement(Box, { pad: "medium", justify: "center", align: "center" },
                    React.createElement(ConfirmButton, { margin: size === 'xsmall' ? 'medium' : 'large', primary: true, label: "Confirmar", type: "submit" })),
                React.createElement(QuizMeter, { value: values.TotalRespondidas || 0 })))))));
});
Questionario.displayName = 'Questionario';
export default Questionario;
