import Axios from '../../../../Utils/axios';
const validaChave = async (chaveGuid) => {
    let valida = {
        Success: true,
    };
    await Axios().get(`Chaves/ValidarChaveAntesDoQuestionario/${chaveGuid}`)
        .then((response) => {
        const { data } = response;
        valida = data;
        return valida;
    })
        .catch(() => {
        valida.Success = false;
        return valida;
    });
    return valida;
};
export default validaChave;
