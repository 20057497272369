import Axios from '../../../Utils/axios';
const recuperarSenhaNovaSenha = async (values) => {
    let recuperar = {};
    await Axios().post('Identity/RecuperarSenhaNovaSenha', values)
        .then((response) => {
        const { data } = response;
        recuperar = data;
        return recuperar;
    })
        .catch(() => {
        recuperar.Success = false;
        return recuperar;
    });
    return recuperar;
};
export default recuperarSenhaNovaSenha;
