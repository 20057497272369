import { Facebook, Instagram, Linkedin, Twitter, } from 'grommet-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { FollowUsBox, FooterContainer, MainBox, Sector1, TextParagraph, TinyText, FollowUsBoxIcon, ImageLogo, } from './Styles/StyledComponents';
const Footer = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    return (React.createElement(FooterContainer, { fill: "horizontal", align: "center" },
        React.createElement(MainBox, null,
            React.createElement(Sector1, null,
                React.createElement(ImageLogo, { src: "/public/Hex02.png" }),
                React.createElement(FollowUsBox, null,
                    React.createElement(TextParagraph, null, "Siga-nos nas redes:"),
                    React.createElement(FollowUsBoxIcon, null,
                        React.createElement(Facebook, { size: "25px", color: "brand" }),
                        React.createElement(Twitter, { size: "25px", color: "brand" }),
                        React.createElement(Instagram, { size: "25px", color: "brand" }),
                        React.createElement(Linkedin, { size: "25px", color: "brand" })))),
            React.createElement(TinyText, null, "\u00A9 2022 Hextriade. Todos os direitos reservados. All rights reserved."))));
});
Footer.displayName = 'Footer';
export default Footer;
