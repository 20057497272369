import Axios from '../../../../Utils/axios';
const getConsultorEPerfilDominte = async (chaveGuid) => {
    let questao = {
        Success: true,
    };
    await Axios().get(`Chaves/GetConsultorPorChaveGuid/${chaveGuid}`)
        .then((response) => {
        const { data } = response;
        questao = data;
        return questao;
    })
        .catch(() => {
        questao.Success = false;
        return questao;
    });
    return questao;
};
export default getConsultorEPerfilDominte;
