import { Button, Image } from 'grommet';
import styled from 'styled-components';
const InviteButton = styled(Button) `
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  align-self: flex-end;

  @media (max-width: 920px) {
    padding: 0.8rem;
    font-size: 16px;
    max-width: 50%;
  }

  @media (max-width: 768px) {
    max-width: 50%;
  }

  @media (max-width: 680px) {
    padding: 0.5rem;
    font-size: 12px;
  }

  @media (max-width: 420px) {
    padding: 0.2rem;
    font-size: 8px;
  }
`;
export default InviteButton;
export const TableButton = styled(Button) `
  padding: 1rem;
  border-radius: 8px;
  border-color: #444444;

  @media (max-width: 920px) {
    padding: 0.8rem;
    font-size: 16px;
  }

  @media (max-width: 680px) {
    padding: 0.5rem;
    font-size: 12px;
  }

  @media (max-width: 420px) {
    padding: 0.2rem;
    font-size: 8px;
  }
`;
export const ImageTableHEX = styled(Image) `
width: 25px;
padding: .5rem;
`;
