import { Box, Heading } from 'grommet';
import styled from 'styled-components';
const ErrorPageContainer = styled(Box) `
  max-width: 100%;
  margin-top: 8%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
export default ErrorPageContainer;
export const CardErrorContainer = styled(Box) `
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1em;
  margin-top: 1%;
  @media (max-width: 680px) {
    max-width: max-content;
    gap: 0.2em;
  }
`;
export const HeadingTitle = styled(Heading) `
  font-size: 15rem;
  margin: 60px 0;
  @media (max-width: 680px) {
    font-size: 5rem;
    margin: 10px 0;
  }
`;
