import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
// import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
import AppContext from './Components/Template/AppContext/AppContext';

loadableReady().then(() => {
  hydrate(
    <BrowserRouter>
      <AppContext initialContext={window.initialContext}>
        <App />
      </AppContext>
    </BrowserRouter>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}
