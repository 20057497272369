import Axios from '../../../../Utils/axios';
const responderQuestao = async (values) => {
    let responder = {};
    const valuesParaEnviar = {
        Resposta: parseInt((values.Resposta.toString() || '0'), 10),
        ...values,
    };
    await Axios().post('Chaves/QuestaoResponder', valuesParaEnviar)
        .then((response) => {
        const { data } = response;
        responder = data;
        return responder;
    })
        .catch(() => {
        responder.Success = false;
        return responder;
    });
    return responder;
};
export default responderQuestao;
