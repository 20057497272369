import React, { useContext } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Add } from 'grommet-icons';
import InviteButton from '../HexpassTable/Styles/StyledComponents';
const AddButton = (props) => {
    const { Text, Title, Pad = 'small', Align = 'end', onClick, } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(Box, { pad: (Pad) && Pad, width: "50%", align: (Align) && Align },
        React.createElement(InviteButton, { gap: size === 'xsmall' ? 'xsmall' : 'small', title: Title, label: Text, icon: React.createElement(Add, { color: "#fff", size: size === 'xsmall' ? '16px' : '22px' }), onClick: onClick, primary: true })));
};
AddButton.displayName = 'AddButton';
export default AddButton;
