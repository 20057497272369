import React, { useEffect, useState } from 'react';
import { Box, FormField, TextInput, Button, } from 'grommet';
import { Save } from 'grommet-icons';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import recuperarSenhaValidarGuid from './Helpers/recuperarSenhaValidarGuid';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import Loading from '../Loading/Loading';
import FormWrapper from '../FormWrapper/FormWrapper';
import recuperarSenhaNovaSenha from './Helpers/recuperarSenhaNovaSenha';
import recuperarSenhaNovaSenhaInitalValues from './Helpers/recuperarSenhaNovaSenhaInitialValues';
const ConfirmaRecuperacaoDeSenha = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [values, setValues] = useState(recuperarSenhaNovaSenhaInitalValues(guid));
    const [submitting, setSubmitting] = useState(false);
    const onChange = (newValue) => { setValues(newValue); };
    const onSubmit = async () => {
        setSubmitting(true);
        await recuperarSenhaNovaSenha(values)
            .then((data) => {
            if (data.Success) {
                toast.success('Se o email digitado estava correto, você receberá uma mensagem em breve para gerar uma nova senha');
                history.push('/');
            }
            else {
                toast.error('Desculpe, mas houve um erro!');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas houve um erro!');
        });
    };
    const getData = async () => {
        setLoading(true);
        await recuperarSenhaValidarGuid(guid)
            .then((data) => {
            if (data.Success && data.Object && data.Object > 0) {
                setLoading(false);
            }
            else {
                toast.error('Desculpe, código de recuperação inválido ou expirado!');
            }
        })
            .catch(() => {
            toast.error('Desculpe, código de recuperação inválido ou expirado!');
        });
    };
    const validarSenha = (value) => {
        if (value.length < 6)
            return 'Mínimo de seis caracteres';
        return undefined;
    };
    const validarConfirmarSenha = (value) => {
        if (value !== values.Senha)
            return 'Senha e Confirmar senha devem ser iguais';
        return undefined;
    };
    useEffect(() => {
        getData();
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Recupera\u00E7\u00E3o de senha" }),
        (loading) ? (React.createElement(Loading, null)) : (React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: onSubmit },
            React.createElement(Box, { direction: "row", justify: "evenly", gap: "small", responsive: true },
                React.createElement(FormField, { label: "Nova senha", name: "Senha", width: "50%", required: true, validate: [validarSenha] },
                    React.createElement(TextInput, { name: "Senha", type: "password", "aria-label": "Por favor informe a nova senha", title: "Por favor informe a nova senha", placeholder: "******", maxLength: 95 })),
                React.createElement(FormField, { label: "Confirmar senha", name: "ConfirmarSenha", width: "50%", required: true, validate: [validarConfirmarSenha] },
                    React.createElement(TextInput, { name: "ConfirmarSenha", type: "password", "aria-label": "Por favor confirme a senha", title: "Por favor confirme a senha", placeholder: "******", maxLength: 95 }))),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(Button, { icon: React.createElement(Save, null), type: "submit", label: "Recuperar senha", title: "Clique aqui para recuperar a senha", primary: true, reverse: true, disabled: submitting }))))));
});
ConfirmaRecuperacaoDeSenha.displayName = 'ConfirmaRecuperacaoDeSenha';
export default ConfirmaRecuperacaoDeSenha;
