import { Box, Button, Paragraph } from 'grommet';
import { Contact, Home } from 'grommet-icons';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../PageTitle/PageTitle';
import PageWrapper from '../../PageWrapper/PageWrapper';
const ChaveCancelada = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const onClickHome = () => { history.push('/'); };
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Chave n\u00E3o dispon\u00EDvel" }),
        React.createElement(Box, { fill: "horizontal", justify: "center", align: "center" },
            React.createElement(Box, { width: "large" },
                React.createElement(Paragraph, { fill: true }, "Esta chave n\u00E3o est\u00E1 mais dispon\u00EDvel. Caso tenha alguma d\u00FAvida, clique no bot\u00E3o abaixo para entrar em contato com consultor que a enviou."))),
        React.createElement(Box, { fill: "horizontal", justify: "center", align: "center", direction: "row", gap: "medium", pad: {
                vertical: 'small',
            } },
            React.createElement(Button, { size: "large", label: "Voltar ao in\u00EDcio", icon: React.createElement(Home, null), primary: true, onClick: onClickHome }),
            React.createElement(Button, { size: "large", label: "Entrar em contato", icon: React.createElement(Contact, null), href: "mailto:ricardogaefke@gmail.com?subject=Chave n\u00E3o dispon\u00EDvel", primary: true }))));
});
ChaveCancelada.displayName = 'ChaveCancelada';
export default ChaveCancelada;
