import { Box } from 'grommet';
import React from 'react';
import { withTranslation } from 'react-i18next';
import TransferenciaLinhaDeInfo from './TransferenciaLinhaDeInfo';
import TransferenciaLinhaDeLicencas from './TransferenciaLinhaDeLicencas';
const TransferenciaInfo = withTranslation()((props) => {
    const { transferencia } = props;
    return (React.createElement(Box, { fill: "horizontal", align: "center" },
        React.createElement(Box, { width: "large", border: "top" },
            React.createElement(TransferenciaLinhaDeInfo, { titulo: "Nome", texto: `${transferencia.Nome} ${transferencia.Sobrenome}` }),
            React.createElement(TransferenciaLinhaDeInfo, { titulo: "Email", texto: transferencia.Email }),
            (transferencia.Empresa && transferencia.Empresa.length > 2) && (React.createElement(TransferenciaLinhaDeInfo, { titulo: "Empresa", texto: transferencia.Empresa })),
            React.createElement(TransferenciaLinhaDeLicencas, { transferencia: transferencia }))));
});
TransferenciaInfo.displayName = 'TransferenciaInfo';
export default TransferenciaInfo;
