import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from 'grommet';
import PageTitle from '../../PageTitle/PageTitle';
import PageWrapper from '../../PageWrapper/PageWrapper';
import getTransferenciaInfoParaRecebimento from './Helpers/getTransferenciaInfoParaRecebimento';
import Loading from '../../Loading/Loading';
import TransferenciaInfo from './Components/TransferenciaInfo';
import verificaTransferenciaInfo from './Helpers/verificaTransferenciaInfo';
import IniciaRecebimentoForm from './Components/IniciaRecebimento/IniciaRecebimentoForm/IniciaRecebimentoForm';
const ReceberChaves = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid } = useParams();
    const [loading, setLoading] = useState(true);
    const [transferencia, setTransferencia] = useState({});
    const updateTransferencia = (data) => { setTransferencia(data); };
    const getTransferenciaInfo = async () => {
        setLoading(true);
        await getTransferenciaInfoParaRecebimento(guid)
            .then((data) => {
            if (data.Success) {
                updateTransferencia(data.Object);
                setLoading(false);
            }
            else {
                toast.error('Desculpe, chave não encontrada');
            }
        })
            .catch(() => {
            toast.error('Desculpe, chave não encontrada');
        });
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { initialData } = window;
            const validacao = {
                transferencia: initialData,
                setTransferencia: updateTransferencia,
            };
            if (verificaTransferenciaInfo(validacao)) {
                setLoading(false);
                return;
            }
        }
        getTransferenciaInfo();
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Receber transfer\u00EAncia de chaves" }),
        (loading) ? (React.createElement(Loading, null)) : (React.createElement(Box, { fill: "horizontal", align: "center" },
            React.createElement(TransferenciaInfo, { transferencia: transferencia }),
            React.createElement(IniciaRecebimentoForm, { email: transferencia.Email })))));
});
ReceberChaves.displayName = 'ReceberChaves';
export default ReceberChaves;
