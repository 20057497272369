import { Box, Paragraph } from 'grommet';
import React from 'react';
import { withTranslation } from 'react-i18next';
const TransferenciaLinhaDeInfo = withTranslation()((props) => {
    const { titulo, texto } = props;
    return (React.createElement(Box, { fill: "horizontal", direction: "row", border: "bottom", background: "background-back", pad: "xsmall" },
        React.createElement(Box, { width: "small" },
            React.createElement(Paragraph, { margin: "xsmall", fill: true },
                React.createElement("strong", null, titulo),
                ":")),
        React.createElement(Box, { flex: true },
            React.createElement(Paragraph, { margin: "xsmall", fill: true }, texto))));
});
TransferenciaLinhaDeInfo.displayName = 'TransferenciaLinhaDeInfo';
export default TransferenciaLinhaDeInfo;
