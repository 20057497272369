const criarUsuarioInitialValues = (source) => ({
    Email: '',
    ConfirmarEmail: '',
    Nome: '',
    Sobrenome: '',
    Senha: '',
    ConfirmarSenha: '',
    Telefone: '',
    Empresa: '',
    Cargo: '',
    LeadSource: source,
    Formulario: 'Registro Hextríade',
    Consultor: '',
});
export default criarUsuarioInitialValues;
