import { Button, Paragraph, ResponsiveContext, Text, } from 'grommet';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPageContainer, { CardErrorContainer, HeadingTitle, } from './Styles/StyledComponents';
import { ImageLogo } from '../LoginPage/Styles/StyledComponents';
const NotAllowed = () => {
    const size = useContext(ResponsiveContext);
    const history = useHistory();
    const GoToHome = () => {
        history.push('/');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ErrorPageContainer, null,
            React.createElement(ImageLogo, { width: "30%", src: "/public/Hex02.png" }),
            React.createElement(Paragraph, { size: size === 'xsmall' ? '2rem' : '3rem', margin: "1rem", alignSelf: "center" }, "Erro"),
            React.createElement(HeadingTitle, { color: "#1373AD" }, "403"),
            React.createElement(CardErrorContainer, null,
                React.createElement(Paragraph, { size: size === 'xsmall' ? 'small' : 'large', alignSelf: "center" }, "Acesso n\u00E3o autorizado"),
                React.createElement(Paragraph, { size: size === 'xsmall' ? 'small' : 'large', alignSelf: "center" }, "|"),
                React.createElement(Button, { onClick: GoToHome },
                    React.createElement(Text, { size: size === 'xsmall' ? 'small' : 'large', color: "brand" }, "Ir para p\u00E1gina inicial"))))));
};
NotAllowed.displayName = 'NotAllowed';
export default NotAllowed;
