import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../../404/NotFound';
import AppWrapper from '../../Template/GrommetWrapper/AppWrapper';
import TransferirChaves from '../../Chaves/Transferir/TransferirChaves';
import Transferencias from '../../Chaves/Listar/Transferencias';
import ReceberChaves from '../../Chaves/Receber/ReceberChaves';
import CriarContaDeConsultor from '../../Chaves/Receber/Components/IniciaRecebimento/CriarContaDeConsultor/CriarContaDeConsultor';
import CriarContaParaProjeto from '../../Client/Projetos/Components/Ingressar/Components/CriarContaParaProjeto/CriarContaParaProjeto';
import ResponderQuestionario from '../../Chaves/Responder/ResponderQuestionario';
import ChaveCancelada from '../../Chaves/Cancelada/ChaveCancelada';
import Questionario from '../../Chaves/Questionario/Questionario';
import FinalizarQuestionario from '../../Chaves/Finalizar/FinalizarQuestionario';
const ChavesRouter = () => (React.createElement(AppWrapper, null,
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/chaves/transferirChaves", component: TransferirChaves }),
        React.createElement(Route, { exact: true, path: "/chaves/transferencias", component: Transferencias }),
        React.createElement(Route, { exact: true, path: "/chaves/receberTransferencia/:guid", component: ReceberChaves }),
        React.createElement(Route, { exact: true, path: "/chaves/criarContaDeConsultor/:guid", component: CriarContaDeConsultor }),
        React.createElement(Route, { exact: true, path: "/chaves/criarContaParaProjeto/:guid", component: CriarContaParaProjeto }),
        React.createElement(Route, { exact: true, path: "/chaves/responderQuestionario/:guid", component: ResponderQuestionario }),
        React.createElement(Route, { exact: true, path: "/chaves/chaveNaoDisponivel/:guid", component: ChaveCancelada }),
        React.createElement(Route, { exact: true, path: "/chaves/questionario/:guid", component: Questionario }),
        React.createElement(Route, { exact: true, path: "/chaves/finalizarQuestionario/:guid", component: FinalizarQuestionario }),
        React.createElement(Route, { component: NotFound }))));
ChavesRouter.displayName = 'ChavesRouter';
export default ChavesRouter;
