import React from 'react';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GrommetTheme from './GrommetTheme';
import { useStateValue } from '../AppContext/StateProvider';
import CookiesConsent from './CookiesConsent/CookiesConsent';
import { StyledGrommet } from './Styles/AppWrapperStyles';
const GrommetWrapper = withTranslation()((props) => {
    const { children } = props;
    const [{ Theme = 'light', ConsentCookie = {} }] = useStateValue();
    const { ShowBanner = false, CookieString = '' } = ConsentCookie;
    return (React.createElement(StyledGrommet, { full: "min", theme: GrommetTheme, themeMode: (Theme === 'light') ? 'light' : 'dark' },
        children,
        React.createElement(ToastContainer, { position: "bottom-right", autoClose: 5000, hideProgressBar: true, newestOnTop: true, closeOnClick: false, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true }),
        (ShowBanner) && (React.createElement(CookiesConsent, { ShowBanner: ShowBanner, CookieString: CookieString }))));
});
GrommetWrapper.displayName = 'GrommetWrapper';
export default GrommetWrapper;
