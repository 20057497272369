import { Box, List, Paragraph } from 'grommet';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
const TransferenciaLinhaDeLicencas = withTranslation()((props) => {
    const { transferencia } = props;
    const [chaves, setChaves] = useState([]);
    const { Degustacao, Pessoal, Avancada, Profissional, } = transferencia;
    const setDados = () => {
        const tempChaves = [];
        if (Degustacao && Degustacao > 0) {
            tempChaves.push({ chave: 'Degustação', total: Degustacao });
        }
        if (Pessoal && Pessoal > 0) {
            tempChaves.push({ chave: 'Pessoal', total: Pessoal });
        }
        if (Profissional && Profissional > 0) {
            tempChaves.push({ chave: 'Profissional', total: Profissional });
        }
        if (Avancada && Avancada > 0) {
            tempChaves.push({ chave: 'Avancada', total: Avancada });
        }
        setChaves(tempChaves);
    };
    useEffect(() => {
        setDados();
    }, []);
    return (React.createElement(Box, { fill: "horizontal", direction: "row", border: "bottom", background: "background-back", pad: "xsmall" },
        React.createElement(Box, { width: "small" },
            React.createElement(Paragraph, { margin: "xsmall", fill: true },
                React.createElement("strong", null, "Licen\u00E7as:"))),
        React.createElement(Box, { flex: true },
            React.createElement(List, { primaryKey: "chave", secondaryKey: "total", data: chaves }))));
});
TransferenciaLinhaDeLicencas.displayName = 'TransferenciaLinhaDeLicencas';
export default TransferenciaLinhaDeLicencas;
