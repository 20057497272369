import { Box, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { DividerBox, PromoButton, RegularText, ResultImage, ResultVideoBox, ResultWidthBox, SubtitleText, } from './Styles/StyledComponents';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import PromoCard from './components/PromoCard/PromoCard';
import CarrosselCard from './components/CarrosselCard/CarrosselCard';
const Realizador = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(PageWrapper, null,
        React.createElement(ResultWidthBox, { background: "#fff", margin: size === 'xsmall' ? '2rem' : '0rem' }, size === 'xsmall' ? (React.createElement(ResultImage, { src: "/public/Realizador_mob.png" })) : (React.createElement(ResultImage, { src: "/public/Realizador_desk.png" }))),
        React.createElement(Box, { align: "center", justify: "center", pad: size === 'xsmall' ? 'small' : 'large', fill: true },
            React.createElement(Heading, { level: size === 'small' ? '4' : '3', fill: true, color: "#1373AD", margin: "1rem", textAlign: size === 'xsmall' ? 'center' : 'start' }, "Alcance grandes objetivos e destaque-se como realizador."),
            React.createElement(SubtitleText, { textAlign: size === 'xsmall' ? 'center' : 'start', margin: {
                    bottom: `${size === 'xsmall'
                        ? '2rem !important'
                        : `${size === 'small' ? '1rem !important' : '0 !important'}`}`,
                } }, "Assista ao v\u00EDdeo abaixo e saiba como a Hextr\u00EDade pode te ajudar a ser valorizado pelo seu trabalho."),
            React.createElement(ResultVideoBox, { margin: "2% 0", fill: "vertical", align: "center" },
                React.createElement("iframe", { src: "https://player-vz-5799dede-43a.tv.pandavideo.com.br/embed/?v=1f8d91d5-07bf-415d-abd0-fcf7c781e742", width: "100%", height: size === 'xsmall'
                        ? '220px'
                        : `${size === 'small' ? '260px' : '420px'}`, title: "Realizador video" })),
            React.createElement(Box, { pad: size === 'xsmall' ? 'medium' : 'none' },
                React.createElement("a", { href: "mailto:hexpass@hextriade.com?subject=Quero comprar uma chave" },
                    React.createElement(PromoButton, { primary: true, color: "status-ok" }, "COMPRE AGORA")))),
        React.createElement(CarrosselCard, null),
        React.createElement(DividerBox, { direction: "row", gap: "1rem", width: "80%", pad: { bottom: 'xlarge' } }),
        React.createElement(ResultWidthBox, { align: "center", justify: "center", pad: "medium" },
            React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "2rem 0rem 1rem" }, "Descubra o segredo da sua transforma\u00E7\u00E3o comportamental atrav\u00E9s do M\u00E9todo HEXTR\u00CDADE e seu MECANISMO MENTAL3.18.162.:")),
        React.createElement(RegularText, { textAlign: size === 'small' ? 'center' : 'start', margin: { bottom: '1rem !important' } },
            "O",
            ' ',
            React.createElement("strong", null, "m\u00E9todo inovador HEXTR\u00CDADE"),
            ' ',
            "oferece insights poderosos sobre os diferentes perfis dominantes de pensamento, a\u00E7\u00E3o e emo\u00E7\u00E3o, permitindo que voc\u00EA compreenda melhor como",
            ' ',
            React.createElement("strong", null, "enfrentar desafios"),
            ' ',
            "e",
            ' ',
            React.createElement("strong", null, "aproveitar oportunidades"),
            ' ',
            "em v\u00E1rias situa\u00E7\u00F5es."),
        React.createElement(RegularText, { textAlign: size === 'small' ? 'center' : 'start' },
            "Seja voc\u00EA um",
            ' ',
            React.createElement("strong", null, "Anal\u00EDtico super-racional"),
            ", um",
            ' ',
            React.createElement("strong", null, "Comandante controlador"),
            ' ',
            "ou um",
            ' ',
            React.createElement("strong", null, "Criativo com habilidades inovadoras"),
            ", o",
            ' ',
            React.createElement("strong", null, "HEXTR\u00CDADE"),
            ' ',
            "proporcionar\u00E1 uma abordagem abrangente para aprimorar sua adaptabilidade e melhorar suas intera\u00E7\u00F5es pessoais e profissionais, promovendo verdadeira versatilidade comportamental e",
            ' ',
            React.createElement("strong", null, "maximizando seu potencial"),
            "."),
        React.createElement(Box, null,
            React.createElement("a", { href: "mailto:hexpass@hextriade.com?subject=Quero comprar uma chave" },
                React.createElement(PromoButton, { primary: true, color: "status-ok" }, "COMPRE AGORA"))),
        React.createElement(DividerBox, { direction: "row", gap: "1rem", width: "80%", pad: { bottom: 'xlarge' } }),
        React.createElement(ResultWidthBox, { align: "center", justify: "center", pad: "medium", width: size === 'small' ? '100%' : '65%' },
            React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem 1rem 3rem" }, "Aproveite essa oportunidade \u00FAnica que vai transformar sua vida."),
            React.createElement(PromoCard, null))));
});
Realizador.displayName = 'Realizador';
export default Realizador;
