import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Voc\u00EA, como planejador meticuloso, \u00E9 algu\u00E9m que valoriza a estabilidade e a seguran\u00E7a."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Quando imprevistos surgem e interrompem seus planos cuidadosamente elaborados, isso pode gerar preocupa\u00E7\u00E3o e medo. Como um planejador detalhista, voc\u00EA se esfor\u00E7a para criar uma estrutura justa em suas decis\u00F5es e expectativas. Quando algo sai do curso e voc\u00EA se sente prejudicado, pode surgir um sentimento de injusti\u00E7a, afetando sua motiva\u00E7\u00E3o e confian\u00E7a. Mas n\u00E3o se preocupe, planejador, a solu\u00E7\u00E3o est\u00E1 ao seu alcance.")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Na Hextr\u00EDade, acreditamos que a adaptabilidade \u00E9 a chave para o sucesso em um mundo em constante mudan\u00E7a."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Portanto, planejador, queremos ajud\u00E1-lo a utilizar suas habilidades de planejamento para criar estrat\u00E9gias flex\u00EDveis, permitindo que voc\u00EA alcance seus objetivos mesmo diante de imprevistos. Junte-se a n\u00F3s hoje mesmo e descubra como nossa metodologia pode ajud\u00E1-lo nas oportunidades que valorizam seu perfil planejador, ao mesmo tempo em que fortalece suas habilidades de adaptabilidade e resili\u00EAncia."))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
