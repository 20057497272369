import { Box, Button, FormField, TextInput, } from 'grommet';
import { Close, Login } from 'grommet-icons';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../../../FormWrapper/FormWrapper';
import RegExpForEmailValidation, { confirmEmailValidation, } from '../../../../Utils/fieldValidation';
import { useStateValue } from '../../../Template/AppContext/StateProvider';
const CriarUsuarioForm = withTranslation()((props) => {
    const { initialValues, onSubmit, onSuccess, url = '/cliente', } = props;
    const history = useHistory();
    const [, dispatch] = useStateValue();
    const [values, setValues] = useState(initialValues);
    const [submitting, setSubmitting] = useState(false);
    const onChange = (newValues) => {
        setValues(newValues);
    };
    const submit = async (event) => {
        const { value } = event;
        setSubmitting(true);
        await onSubmit(value)
            .then((data) => {
            if (data.Success) {
                toast.success('Conta criada com sucesso');
                const user = data.Object;
                if (onSuccess)
                    onSuccess(data.Object);
                dispatch({
                    type: 'changeNome',
                    value: user.Nome,
                });
                dispatch({
                    type: 'changeSobrenome',
                    value: user.Sobrenome,
                });
                dispatch({
                    type: 'changeEmail',
                    value: user.Email,
                });
                dispatch({
                    type: 'changeAuth',
                    value: true,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: true,
                });
                if (data.Code) {
                    history.push(`/chaves/responderQuestionario/${data.Code}`);
                }
                else {
                    history.push(url);
                }
            }
            else {
                toast.error('Desculpe, mas houve um erro ao criar a conta');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas houve um erro ao criar a conta');
        });
    };
    const validarSenha = (senha) => {
        if (senha.length < 6) {
            return 'Senha deve ter ao menos seis dígitos';
        }
        return undefined;
    };
    const validarConfirmarSenha = (confirmar) => {
        if (confirmar !== values.Senha) {
            return 'Senha e Confirmar senha deves ser iguais';
        }
        return undefined;
    };
    const onCancel = () => { history.push('/'); };
    return (React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: submit },
        React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
            React.createElement(FormField, { label: "E-mail", name: "Email", width: "50%", required: true, validate: {
                    regexp: RegExpForEmailValidation,
                    message: 'E-mail inválido',
                } },
                React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Por favor informe o e-mail", title: "Por favor informe o email", placeholder: "email@email.com", maxLength: 95 })),
            React.createElement(FormField, { label: "Confirmar e-mail", name: "ConfirmarEmail", width: "50%", required: true, validate: [
                    (ConfirmarEmail) => confirmEmailValidation(values.Email, ConfirmarEmail),
                ] },
                React.createElement(TextInput, { name: "ConfirmarEmail", type: "email", "aria-label": "Por favor confirme o e-mail", title: "Por favor confirme o email", placeholder: "confirmar@email.com", maxLength: 95 }))),
        React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
            React.createElement(FormField, { label: "Nome", name: "Nome", width: "50%", required: true },
                React.createElement(TextInput, { name: "Nome", "aria-label": "Por favor informe o nome", title: "Por favor informe o nome", placeholder: "Nome", maxLength: 95 })),
            React.createElement(FormField, { label: "Sobrenome", name: "Sobrenome", width: "50%", required: true },
                React.createElement(TextInput, { name: "Sobrenome", "aria-label": "Por favor confirme o sobrenome", title: "Por favor confirme o sobrenome", placeholder: "Sobrenome", maxLength: 95 }))),
        React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
            React.createElement(FormField, { label: "Telefone", name: "Telefone", width: "50%", required: true },
                React.createElement(TextInput, { name: "Telefone", "aria-label": "Por favor informe o Telefone", title: "Por favor informe o Telefone", placeholder: "Telefone", maxLength: 95 })),
            React.createElement(FormField, { label: "\u00C1rea de atua\u00E7\u00E3o", name: "Cargo", width: "50%", required: true },
                React.createElement(TextInput, { name: "Cargo", "aria-label": "Por favor informe a \u00E1rea de atua\u00E7\u00E3o", title: "Por favor informe a \u00E1rea de atua\u00E7\u00E3o", placeholder: "\u00C1rea de atua\u00E7\u00E3o", maxLength: 95 }))),
        React.createElement(Box, { direction: "row", justify: "between", gap: "small", responsive: true },
            React.createElement(FormField, { label: "Senha", name: "Senha", width: "50%", required: true, validate: [
                    (senha) => validarSenha(senha),
                ] },
                React.createElement(TextInput, { name: "Senha", type: "password", "aria-label": "Por favor informe a senha", title: "Por favor informe a senha", placeholder: "******" })),
            React.createElement(FormField, { label: "Confirmar senha", name: "ConfirmarSenha", width: "50%", required: true, validate: [
                    (validar) => validarConfirmarSenha(validar),
                ] },
                React.createElement(TextInput, { name: "ConfirmarSenha", type: "password", "aria-label": "Por favor confirme a senha", title: "Por favor confirme a senha", placeholder: "******" }))),
        React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'medium' } },
            React.createElement(Button, { icon: React.createElement(Login, null), type: "submit", label: "Criar conta", title: "Clique aqui para criar a conta", primary: true, reverse: true, disabled: submitting }),
            React.createElement(Button, { icon: React.createElement(Close, null), color: "status-critical", label: "Cancelar", title: "Clique aqui para voltar \u00E0 lista de projetos", onClick: onCancel, primary: true, reverse: true }))));
});
CriarUsuarioForm.displayName = 'CriarUsuarioForm';
export default CriarUsuarioForm;
