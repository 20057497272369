import Axios from '../../../../../../../Utils/axios';
const criarContaDeConsultorSubmit = async (values) => {
    let signin = {};
    await Axios().post('Identity/CriarContaDeConsultor', values)
        .then((response) => {
        const { data } = response;
        signin = data;
        return signin;
    })
        .catch(() => {
        signin.Success = false;
        return signin;
    });
    return signin;
};
export default criarContaDeConsultorSubmit;
