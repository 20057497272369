import styled from 'styled-components';
import { Box, Image, Paragraph, Text, } from 'grommet';
export const FooterContainer = styled(Box) `
  border-top: 1px solid rgb(204, 204, 204);
  text-align: center;
`;
export const MainBox = styled(Box) `
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
`;
export const Sector1 = styled(Box) `
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1%;

  @media(max-width: 800px) {
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10%;
  }
`;
export const ImageLogo = styled(Image) `
  width: 15%;

  @media(max-width: 800px) {
    width: 40%;
    margin-bottom: 5%;
  }

  @media(max-width: 600px) {
    width: 50%;
    margin-bottom: 8%;
  }
`;
export const FollowUsBox = styled(Box) `
  flex-direction: column;

  @media(max-width: 800px) {
    margin-bottom: 5%;
  }

  @media(max-width: 600px) {
    margin-bottom: 8%;
  }
`;
export const FollowUsBoxIcon = styled(Box) `
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
`;
export const TextParagraph = styled(Paragraph) `
  font-size: 1.5rem;
  margin: 0;

  @media(max-width: 600px) {
    font-size: 0.8rem;
  }
`;
export const HiddenText = styled(Text) `
  opacity: 0;
  font-size: 1px;
`;
export const TextIcon = styled(Paragraph) `
  font-size: 1rem;
  color: #fff;
  margin: 0;
  padding-left: 5px;

  @media(max-width: 800px) {
    margin-bottom: 2%;
    font-size: 0.8rem;
  }

  @media(max-width: 600px) {
    font-size: 0.8rem;
  }
`;
export const TinyText = styled(Paragraph) `
  font-size: 1.2rem;
  text-align: center;
  display: contents;

  @media(max-width: 600px) {
    font-size: 0.8rem;
  }
`;
