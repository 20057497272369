import { Box, Meter, Paragraph, Tip, } from 'grommet';
import React from 'react';
import { withTranslation } from 'react-i18next';
const QuizMeter = withTranslation()((props) => {
    const { value } = props;
    return (React.createElement(Box, { align: "center", pad: "small", direction: "row", justify: "center", fill: "horizontal" },
        React.createElement(Paragraph, { size: "small", margin: "1%" },
            value,
            "%"),
        React.createElement(Tip, { content: (React.createElement(Box, { margin: "0", width: { max: 'large' } },
                React.createElement(Paragraph, { size: "small" },
                    value,
                    "% respondido! Vamos l\u00E1 :)"))) },
            React.createElement(Meter, { type: "bar", thickness: "small", color: "#61CE70", value: value })),
        React.createElement(Paragraph, { size: "small", margin: "1%" }, "100%")));
});
QuizMeter.displayName = 'QuizMeter';
export default QuizMeter;
