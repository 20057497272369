import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CriarUsuarioForm from '../../../../../../Identity/CreateUser/Components/CriarUsuarioForm';
import PageTitle from '../../../../../../PageTitle/PageTitle';
import PageWrapper from '../../../../../../PageWrapper/PageWrapper';
import criarContaParaProjetoInitialValues from './Helpers/criarContaParaProjetoInitialValues';
import criarContaParaProjetoSubmit from './Helpers/criarContaParaProjetoSubmit';
const CriarContaParaProjeto = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid } = useParams();
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Crie uma conta para ingressar no projeto" }),
        React.createElement(CriarUsuarioForm, { initialValues: criarContaParaProjetoInitialValues(guid), onSubmit: criarContaParaProjetoSubmit })));
});
CriarContaParaProjeto.displayName = 'CriarContaParaProjeto';
export default CriarContaParaProjeto;
