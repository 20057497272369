const criarContaDeConsultorInitialValues = (transferenciaGuid, email, nome, sobrenome) => ({
    Nome: nome,
    Sobrenome: sobrenome,
    Email: email,
    ConfirmarEmail: '',
    Senha: '',
    ConfirmarSenha: '',
    Transferencia: {
        Guid: transferenciaGuid,
    },
});
export default criarContaDeConsultorInitialValues;
