import { Box, Button, Heading, Layer, Paragraph, } from 'grommet';
import { Close, Like } from 'grommet-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
const ConfirmModal = withTranslation()((props) => {
    const { onClose, onConfirm, closable, title = 'Tem certeza?', text = 'Esta operação não poderá ser desfeita.', } = props;
    const onCloseAction = () => {
        if (closable)
            onClose();
    };
    const onClickClose = () => onClose();
    return (React.createElement(Layer, { position: "center", onClickOutside: onCloseAction, onEsc: onCloseAction, responsive: false, margin: "small" },
        React.createElement(Box, { width: "large", pad: "medium", fill: "horizontal" },
            React.createElement(Heading, { textAlign: "center", level: 3, fill: true }, title),
            React.createElement(Box, { width: "large", align: "center", justify: "center" },
                React.createElement(Paragraph, { textAlign: "center", fill: true }, text))),
        React.createElement(Box, { fill: "horizontal", as: "footer", gap: "small", direction: "row", align: "center", justify: "center", pad: {
                top: 'medium',
                bottom: 'large',
            } },
            React.createElement(Button, { icon: React.createElement(Close, null), color: "status-critical", label: "Cancelar", title: "Clique aqui para cancelar", onClick: onClickClose, primary: true }),
            React.createElement(Button, { icon: React.createElement(Like, null), color: "status-ok", label: "Confirmar", title: "Clique aqui para confirmar", onClick: onConfirm, primary: true, reverse: true }))));
});
ConfirmModal.displayName = 'ConfirmModal';
export default ConfirmModal;
