import Axios from '../../../../../../../Utils/axios';
const confereEmailPreexistente = async (values) => {
    let confereUsuario = {};
    await Axios().post('Chaves/VerificaSeConsultorExiste', values)
        .then((response) => {
        const { data } = response;
        confereUsuario = data;
        return confereUsuario;
    })
        .catch(() => {
        confereUsuario.Success = false;
        return confereUsuario;
    });
    return confereUsuario;
};
export default confereEmailPreexistente;
