import React from 'react';
import { StateProvider } from './StateProvider';
const AppContext = (props) => {
    const { initialContext, children } = props;
    const reducer = (state, action) => {
        switch (action.type) {
            case 'changeReady':
                return {
                    ...state,
                    Ready: action.value,
                };
            case 'changeAuth':
                return {
                    ...state,
                    IsAuthenticated: action.value,
                };
            case 'changeLanguage':
                return {
                    ...state,
                    Language: action.value,
                };
            case 'changeTheme':
                return {
                    ...state,
                    Theme: action.value,
                };
            case 'changeConsent':
                return {
                    ...state,
                    ConsentCookie: action.value,
                };
            case 'changeNome':
                return {
                    ...state,
                    Nome: action.value,
                };
            case 'changeSobrenome':
                return {
                    ...state,
                    Sobrenome: action.value,
                };
            case 'changeEmail':
                return {
                    ...state,
                    Email: action.value,
                };
            case 'changeDrawer':
                return {
                    ...state,
                    Drawer: action.value,
                };
            case 'changeConfig':
                return {
                    ...state,
                    Config: action.value,
                };
            case 'changeValidated':
                return {
                    ...state,
                    Validated: action.value,
                };
            case 'changeAdmin':
                return {
                    ...state,
                    Admin: action.value,
                };
            default:
                return state;
        }
    };
    return (React.createElement(StateProvider, { initialContext: initialContext, reducer: reducer },
        React.createElement(React.Fragment, null, children)));
};
AppContext.displayName = 'AppContext';
export default AppContext;
