import { Anchor, Box, DropButton, Nav, } from 'grommet';
import { Logout, Menu, Moon, Send, Sun, UserSettings, } from 'grommet-icons';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../AppContext/StateProvider';
import signout from './signout';
const IdentityManagerLinks = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const [{ Theme, Admin }, dispatch] = useStateValue();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const onNavigateAdmin = () => {
        history.push('/identity/gerenciarConta');
        setOpen(false);
    };
    const onNavigateTransferir = () => {
        history.push('/chaves/transferencias');
        setOpen(false);
    };
    const changeOpen = () => { setOpen(!open); };
    const onSignOut = async () => {
        await signout()
            .then((data) => {
            if (data.Success) {
                toast.success('Conexão encerrada com sucesso');
                dispatch({
                    type: 'changeNome',
                    value: '',
                });
                dispatch({
                    type: 'changeSobrenome',
                    value: '',
                });
                dispatch({
                    type: 'changeEmail',
                    value: '',
                });
                dispatch({
                    type: 'changeAuth',
                    value: false,
                });
                dispatch({
                    type: 'changeAdmin',
                    value: false,
                });
                dispatch({
                    type: 'changeDrawer',
                    value: false,
                });
                dispatch({
                    type: 'changeValidated',
                    value: false,
                });
                history.push('/');
            }
            else {
                toast.error('Desculpe, mas não foi possível encerrar a conexão');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possível encerrar a conexão');
        });
    };
    const ThemeIcon = (Theme === 'dark') ? Sun : Moon;
    const changeTheme = () => {
        const newTheme = (Theme === 'dark') ? 'light' : 'dark';
        dispatch({
            type: 'changeTheme',
            value: newTheme,
        });
    };
    return (React.createElement(DropButton, { dropAlign: { top: 'bottom', right: 'right' }, icon: React.createElement(Menu, null), title: "Gerenciar minha conta", open: open, onOpen: changeOpen, onClose: changeOpen, dropContent: (React.createElement(Box, { gap: "small", justify: "center", pad: "none", border: "all" },
            React.createElement(Nav, { pad: "small" },
                React.createElement(Anchor, { label: (Theme === 'dark') ? 'Tema claro' : 'Tema escuro', title: (Theme === 'dark') ? 'Tema claro' : 'Tema escuro', icon: React.createElement(ThemeIcon, null), onClick: changeTheme }),
                React.createElement(Anchor, { label: "Gerenciar conta", title: "Gerenciar conta", icon: React.createElement(UserSettings, { style: { width: '20px' } }), onClick: onNavigateAdmin }),
                React.createElement(Anchor, { label: "Logout", title: "Logout", icon: React.createElement(Logout, { style: { width: '20px' } }), onClick: onSignOut }),
                (Admin) && (React.createElement(Anchor, { label: "Transferir chaves", title: "Transferir chaves", icon: React.createElement(Send, { style: { width: '20px' } }), onClick: onNavigateTransferir }))))) }));
});
IdentityManagerLinks.displayName = 'IdentityManagerLinks';
export default IdentityManagerLinks;
