const verificaTransferenciaInfo = (dados) => {
    const { transferencia, setTransferencia } = dados;
    let resposta = true;
    if (transferencia.Email && transferencia.Email.length > 3
        && transferencia.Nome && transferencia.Nome.length > 3
        && transferencia.Sobrenome && transferencia.Sobrenome.length > 3
        && ((transferencia.Degustacao || 0) + (transferencia.Pessoal || 0) + (transferencia.Profissional || 0) + (transferencia.Avancada || 0) > 1)) {
        setTransferencia(transferencia);
    }
    else {
        resposta = false;
    }
    return resposta;
};
export default verificaTransferenciaInfo;
