import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageTitle from '../../PageTitle/PageTitle';
import PageWrapper from '../../PageWrapper/PageWrapper';
import CriarUsuarioForm from './Components/CriarUsuarioForm';
import criarUsuarioInitialValues from './Helpers/criarUsuarioInitialValues';
import criarUsuarioSubmit from './Helpers/criarUsuarioSubmit';
const CriarUsuario = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid = 'Cadastros Plataforma' } = useParams();
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Criar usu\u00E1rio" }),
        React.createElement(CriarUsuarioForm, { initialValues: criarUsuarioInitialValues(guid), onSubmit: criarUsuarioSubmit })));
});
CriarUsuario.displayName = 'CriarUsuario';
export default CriarUsuario;
