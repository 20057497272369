import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Imagine-se em uma situa\u00E7\u00E3o em que voc\u00EA se encontra travado,"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "incapaz de enfrentar seus objetivos devido \u00E0 raiva reprimida dentro de voc\u00EA. Essa frustra\u00E7\u00E3o constantemente o leva a tolerar problemas, evitar conflitos e, no final, desistir de tudo em busca de paz. Mas n\u00E3o se preocupe, respire fundo e acalme-se, porque a solu\u00E7\u00E3o est\u00E1 ao seu alcance.")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Portanto, n\u00E3o deixe que o medo de ser desconsiderado limite seu potencial como um mediador habilidoso"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Voc\u00EA merece construir relacionamentos harmoniosos e abandonar a postura tolerante em rela\u00E7\u00E3o aos seus conflitos pessoais. Estamos aqui para apoi\u00E1-lo em sua jornada rumo a se tornar um profissional mais confiante, diligente e realizador. Abrace essa oportunidade de transforma\u00E7\u00E3o!"))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
