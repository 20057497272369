import { Box, Heading, ResponsiveContext, } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../PageWrapper/PageWrapper';
import getTransferencias from './Helpers/getTransferencias';
import Loading from '../../Loading/Loading';
import BackButton from '../../BackButton/BackButton';
import TabelaTransferencias from './Components/TabelaTransferencias';
import AddButton from '../../AddButton/AddButton';
const Transferencias = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const size = useContext(ResponsiveContext);
    const [transferencias, setTransferencias] = useState([]);
    const [loading, setLoading] = useState();
    const getData = async () => {
        await getTransferencias()
            .then((data) => {
            if (data.Success) {
                setTransferencias(data.Object);
                setLoading(false);
            }
            else {
                toast.error('Desculpe, mas não foi possivel obter a lista de transferências');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possivel obter a lista de transferências');
        });
    };
    useEffect(() => {
        getData();
    }, []);
    const onAddClick = () => {
        history.push('/chaves/transferirChaves');
    };
    const GoToHome = () => {
        history.push('/');
    };
    return (React.createElement(PageWrapper, { pad: size === 'xsmall' ? '8px' : '0 24px 24px', margin: "1% 0 0" },
        React.createElement(BackButton, { onClick: GoToHome, pad: "none" }),
        React.createElement(Box, { align: "center", direction: "row", justify: "between", fill: "horizontal", margin: size === 'xsmall' ? '0 0 1%' : '0' },
            React.createElement(Heading, { size: size === 'xsmall' ? '16px' : '28px', color: "#8F8F8F", margin: "1% 0" }, "Licen\u00E7as transmitidas"),
            React.createElement(AddButton, { Text: "Transmitir licen\u00E7as", Pad: "none", Title: "Transmitir licen\u00E7as", onClick: onAddClick })),
        (loading) ? (React.createElement(Loading, null)) : (React.createElement(React.Fragment, null,
            React.createElement(TabelaTransferencias, { transferencias: transferencias, atualizarLista: getData })))));
});
Transferencias.displayName = 'Transferencias';
export default Transferencias;
