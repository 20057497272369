import { Box, Button, Paragraph } from 'grommet';
import styled from 'styled-components';
const InviteBox = styled(Box) `
  padding: 2%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  margin-top: 2rem;

  @media (max-width: 680px) {
    max-width: 70%;
    align-self: center;
    max-height: 180px;
    padding: 1rem;
    margin-top: 1rem;
  }
`;
export default InviteBox;
export const SubtitleText = styled(Paragraph) `
  display: contents;
  font-size: 22px;
  color: #8f8f8f;

  @media (max-width: 680px) {
    font-size: 18px;
  }
`;
export const InviteBoxButton = styled(Button) `
  color: #8f8f8f;
  border-color: #8f8f8f;
  padding: 0.5rem;
  margin: 0.5rem;
  width: 100%;
  border-radius: 8px;

  @media (max-width: 920px) {
    padding: 0.5rem;
    align-self: center;
    font-size: 16px;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 680px) {
    padding: 0.2rem;
    font-size: 12px;
  }
`;
