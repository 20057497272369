import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Paragraph, ResponsiveContext, } from 'grommet';
import { useHistory, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageWrapper from '../../PageWrapper/PageWrapper';
import PageTitle from '../../PageTitle/PageTitle';
import Loading from '../../Loading/Loading';
import getConsultor from './Helpers/getConsultor';
import { ImageLogo } from '../../LoginPage/Styles/StyledComponents';
import getIntroducao from '../Responder/Helpers/getIntroducao';
const FinalizarQuestionario = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const { guid } = useParams();
    const size = useContext(ResponsiveContext);
    const history = useHistory();
    const [analista, setAnalista] = useState({});
    const [loading, setLoading] = useState(true);
    const [introducao, setIntroducao] = useState({});
    const getAllData = async () => {
        setLoading(true);
        const allData = Promise.all([
            getIntroducao(guid),
            getConsultor(guid),
        ]);
        allData
            .then((values) => {
            const [introducaoData, consultorData] = values;
            if (introducaoData.Success) {
                setIntroducao(introducaoData.Object);
            }
            else {
                toast.error('Erro ao obter as informações do usuário');
                return;
            }
            if (consultorData.Success) {
                setAnalista(consultorData.Object);
            }
            else {
                toast.error('Erro ao obter os dados do consultor');
                return;
            }
            setLoading(false);
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possível obter os dados');
            history.push('/');
        });
    };
    useEffect(() => {
        getAllData();
    }, []);
    return (React.createElement(PageWrapper, null,
        React.createElement(Box, { fill: "horizontal", align: "center", justify: "center" }, loading ? (React.createElement(Loading, null)) : (React.createElement(Box, { width: "100%", pad: "0", align: "center", justify: "center", direction: size === 'xsmall' ? 'column' : 'row' },
            React.createElement(Box, { width: "large", pad: "0", align: "center", justify: "center" },
                React.createElement(ImageLogo, { width: "350px", src: "/public/sucess.svg" })),
            React.createElement(Box, { width: "large", pad: "medium", align: "center", justify: "center" },
                React.createElement(PageTitle, { text: `Parabéns, ${introducao.Nome} ${introducao.Sobrenome}!` }),
                React.createElement(Heading, { size: size === 'xsmall' ? 'small' : 'medium', level: "4", margin: "0", fill: true }, "Assessment conclu\u00EDdo com sucesso!"),
                React.createElement(Paragraph, { fill: true, textAlign: "center" },
                    "Seu relat\u00F3rio j\u00E1 est\u00E1 com o analista",
                    ' ',
                    `${analista.Nome} ${analista.Sobrenome}`,
                    ", em breve ele entrar\u00E1 em contato com voc\u00EA para marcar seu feedback!")))))));
});
FinalizarQuestionario.displayName = 'FinalizarQuestionario';
export default FinalizarQuestionario;
