import { Box, Button, FormField, ResponsiveContext, TextArea, TextInput, } from 'grommet';
import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Close, Save } from 'grommet-icons';
import { toast } from 'react-toastify';
import PageTitle from '../../PageTitle/PageTitle';
import PageWrapper from '../../PageWrapper/PageWrapper';
import transferirChavesInitialValues from './Helpers/transferirChavesInitialValues';
import FormWrapper from '../../FormWrapper/FormWrapper';
import onTransferirChavesSubmit from './Helpers/onTransferirChavesSubmit';
import RegExpForEmailValidation, { confirmEmailValidation, } from '../../../Utils/fieldValidation';
import ButtonConfirm from './Styles/StyledComponents';
const TransferirChaves = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const history = useHistory();
    const size = useContext(ResponsiveContext);
    const [values, setValues] = useState(transferirChavesInitialValues());
    const [submitting, setSubmitting] = useState(false);
    const onChange = (newValues) => {
        setValues(newValues);
    };
    const onSubmit = async (event) => {
        const { value } = event;
        await onTransferirChavesSubmit(value)
            .then((data) => {
            if (data.Success) {
                setSubmitting(false);
                toast.success('Chaves transferidas com sucesso');
                history.push('/chaves/transferencias');
            }
            else {
                toast.error('Desculpe, mas não foi possível transferir as chaves');
                setSubmitting(false);
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possível transferir as chaves');
            setSubmitting(false);
        });
    };
    const validarEnvios = () => {
        if (values.Degustacao
            + values.Pessoal
            + values.Profissional
            + values.Avancada
            === 0) {
            return 'Favor enviar ao menos uma chave';
        }
        return undefined;
    };
    const GoBack = () => {
        history.push('/chaves/transferencias');
    };
    return (React.createElement(PageWrapper, null,
        React.createElement(PageTitle, { text: "Transferir chaves" }),
        React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: onSubmit },
            React.createElement(Box, { direction: size === 'xsmall' ? 'column' : 'row', justify: "evenly", gap: "small" },
                React.createElement(FormField, { label: "E-mail", name: "Email", required: true, validate: {
                        regexp: RegExpForEmailValidation,
                        message: 'E-mail inválido',
                    } },
                    React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Por favor informe o e-mail", title: "Por favor informe o email", placeholder: "email@email.com", maxLength: 95 })),
                React.createElement(FormField, { label: "Confirmar e-mail", name: "ConfirmarEmail", required: true, validate: [
                        (ConfirmarEmail) => confirmEmailValidation(values.Email, ConfirmarEmail),
                    ] },
                    React.createElement(TextInput, { name: "ConfirmarEmail", type: "email", "aria-label": "Por favor confirme o e-mail", title: "Por favor confirme o email", placeholder: "confirm@email.com", maxLength: 95 }))),
            React.createElement(Box, { direction: size === 'xsmall' ? 'column' : 'row', justify: "between", gap: "small", border: "bottom" },
                React.createElement(FormField, { label: "Nome", name: "Nome", required: true },
                    React.createElement(TextInput, { name: "Nome", "aria-label": "Por favor informe o nome", title: "Por favor informe o nome", placeholder: "Nome", maxLength: 95 })),
                React.createElement(FormField, { label: "Sobrenome", name: "Sobrenome", required: true },
                    React.createElement(TextInput, { name: "Sobrenome", "aria-label": "Por favor confirme o sobrenome", title: "Por favor confirme o sobrenome", placeholder: "Sobrenome", maxLength: 95 }))),
            React.createElement(Box, { direction: "column", justify: "between", gap: "xsmall", border: "bottom" },
                React.createElement(FormField, { label: "Empresa / Cliente", name: "Empresa", required: true },
                    React.createElement(TextInput, { name: "Empresa", "aria-label": "Por favor informe o nome do cliente", title: "Por favor informe o nome do cliente", placeholder: "Empresa / Cliente", maxLength: 95 })),
                React.createElement(FormField, { label: "Descri\u00E7\u00E3o", name: "Descricao" },
                    React.createElement(TextArea, { name: "Descricao", rows: 3, resize: false, "aria-label": "Por favor informe a descri\u00E7\u00E3o da transfer\u00EAncia", title: "Por favor informe a descri\u00E7\u00E3o da transfer\u00EAncia", placeholder: "Recomendamos incluir uma descri\u00E7\u00E3o para consultas posteriores", maxLength: 3900 }))),
            React.createElement(Box, { direction: size === 'xsmall' ? 'column' : 'row', justify: "between", gap: "small" },
                React.createElement(FormField, { label: "Degusta\u00E7\u00E3o", name: "Degustacao", validate: [() => validarEnvios()] },
                    React.createElement(TextInput, { name: "Degustacao", type: "number", "aria-label": "Por favor informe o n\u00FAmero de chaves do tipo degusta\u00E7\u00E3o", title: "Por favor informe o Por favor informe o n\u00FAmero de chaves do tipo degusta\u00E7\u00E3o" })),
                React.createElement(FormField, { label: "Pessoal", name: "Pessoal" },
                    React.createElement(TextInput, { name: "Pessoal", type: "number", "aria-label": "Por favor informe o n\u00FAmero de chaves do tipo pessoal", title: "Por favor informe o Por favor informe o n\u00FAmero de chaves do tipo pessoal" }))),
            React.createElement(Box, { direction: size === 'xsmall' ? 'column' : 'row', justify: "between", gap: "small" },
                React.createElement(FormField, { label: "Profissional", name: "Profissional" },
                    React.createElement(TextInput, { name: "Profissional", type: "number", "aria-label": "Por favor informe o n\u00FAmero de chaves do tipo profissional", title: "Por favor informe o Por favor informe o n\u00FAmero de chaves do tipo profissional" })),
                React.createElement(FormField, { label: "Avan\u00E7ada", name: "Avancada" },
                    React.createElement(TextInput, { name: "Avancada", type: "number", "aria-label": "Por favor informe o n\u00FAmero de chaves do tipo avan\u00E7ada", title: "Por favor informe o Por favor informe o n\u00FAmero de chaves do tipo avan\u00E7ada" }))),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'large' } },
                React.createElement(ButtonConfirm, { icon: React.createElement(Save, { color: "#fff" }), type: "submit", label: "Transferir chaves", title: "Clique aqui para transferir as chaves", primary: true, reverse: true, disabled: submitting }),
                React.createElement(Button, { icon: React.createElement(Close, null), color: "status-critical", label: "Cancelar", title: "Clique aqui para cancelar transfer\u00EAncia de chaves", primary: true, onClick: GoBack, reverse: true, disabled: submitting })))));
});
TransferirChaves.displayName = 'TransferirChaves';
export default TransferirChaves;
