import Axios from '../../../Utils/axios';
const recuperarSenhaValidarGuid = async (recuperacaoGuid) => {
    let recuperar = {
        Success: true,
    };
    await Axios().get(`Identity/RecuperarSenhaValidarGuid/${recuperacaoGuid}`)
        .then((response) => {
        const { data } = response;
        recuperar = data;
        return recuperar;
    })
        .catch(() => {
        recuperar.Success = false;
        return recuperar;
    });
    return recuperar;
};
export default recuperarSenhaValidarGuid;
