import Axios from '../../../../Utils/axios';
const onTransferirChavesSubmit = async (values) => {
    let transferir = {};
    await Axios().post('Chaves/TransferirChaves', values)
        .then((response) => {
        const { data } = response;
        transferir = data;
        return transferir;
    })
        .catch(() => {
        transferir.Success = false;
        return transferir;
    });
    return transferir;
};
export default onTransferirChavesSubmit;
