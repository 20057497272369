import { Box, Button, Tip } from 'grommet';
import { StatusInfo } from 'grommet-icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
const ActionTransferenciaInfo = withTranslation()((props) => {
    const { transferencia } = props;
    return (React.createElement(Tip, { plain: true, content: (React.createElement(Box, { direction: "column", fill: "horizontal", background: "background-front", gap: "small", pad: "small", border: true },
            React.createElement(Box, { direction: "row" },
                React.createElement("strong", null, "Empresa:"),
                ' ',
                transferencia.Empresa),
            React.createElement(Box, { direction: "row", fill: "horizontal" },
                React.createElement("strong", null, "Descri\u00E7\u00E3o:"),
                ' ',
                transferencia.Descricao))) },
        React.createElement(Box, { fill: "horizontal", direction: "row", justify: "evenly", align: "center" },
            React.createElement(Button, { icon: React.createElement(StatusInfo, null), size: "small", title: "Info" }))));
});
ActionTransferenciaInfo.displayName = 'ActionTransferenciaInfo';
export default ActionTransferenciaInfo;
