import { Button } from 'grommet';
import { Trash } from 'grommet-icons';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../../../ConfirmModal/ConfirmModal';
const ActionTransferenciaExcluir = withTranslation()((props) => {
    const { transferencia, onExcluir } = props;
    const [excluindo, setExcluindo] = useState();
    const alterarExcluindo = () => { setExcluindo(!excluindo); };
    const onCancelarTransferencia = () => {
        onExcluir(transferencia.Guid);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { title: "Cancelar transfer\u00EAncia", icon: React.createElement(Trash, { color: "red" }), onClick: alterarExcluindo }),
        (excluindo) && (React.createElement(ConfirmModal, { closable: true, onClose: alterarExcluindo, onConfirm: onCancelarTransferencia, text: "Tem certeza que deseja cancelar esta transfer\u00EAncia?" }))));
});
ActionTransferenciaExcluir.displayName = 'ActionTransferenciaExcluir';
export default ActionTransferenciaExcluir;
