import { Carousel, Heading, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import CarrosselItemCard, { CarrosselContainer, CarrosselText, CarrosselTextLink, } from './Styles/StyledComponents';
const CarrosselCard = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    return (React.createElement(React.Fragment, null,
        React.createElement(CarrosselContainer, { align: "center", pad: size === 'small' ? 'small' : 'medium', background: "#101728" },
            React.createElement(Carousel, { play: 8000 },
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Como uma pessoa criativa, original, sens\u00EDvel e aut\u00EAntica que voc\u00EA \u00E9,"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "experimenta tamb\u00E9m, ao mesmo tempo, uma sensa\u00E7\u00E3o de isolamento e inseguran\u00E7a. Voc\u00EA pode se encontrar em situa\u00E7\u00F5es em que se sente incompreendido ou desconectado das pessoas ao seu redor, o que afeta sua motiva\u00E7\u00E3o e confian\u00E7a. Mas n\u00E3o se preocupe, a Hextr\u00EDade tem a solu\u00E7\u00E3o perfeita para voc\u00EA! Com nossa abordagem especializada e \u00FAnica, podemos ajud\u00E1-lo a superar esses desafios e aproveitar ao m\u00E1ximo seu perfil criativo.")),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", textAlign: "center", fill: true, color: "#1373AD", margin: "1rem" }, "Como a HEXTR\u00CDADE pode me ajudar?"),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" },
                        "A Hextr\u00EDade \u00E9 uma plataforma inovadora de desenvolvimento profissional que oferece tecnologia de ponta para extrair o m\u00E1ximo do seu potencial e desenvolver suas habilidades. Conhe\u00E7a a",
                        ' ',
                        React.createElement(CarrosselTextLink, { to: "https://hextriade.com.br/", color: "" }, "HEXTR\u00CDADE"))),
                React.createElement(CarrosselItemCard, null,
                    React.createElement(Heading, { level: "3", fill: true, color: "#1373AD", margin: "1rem", textAlign: "center" }, "Por isso, meu amigo criativo..."),
                    React.createElement(CarrosselText, { fill: true, textAlign: "center" }, "Sendo uma pessoa aut\u00EAntica, voc\u00EA valoriza profundamente a conex\u00E3o e o apoio de outras pessoas. O medo de ser deixado de lado pode gerar vitimismo, impactando sua capacidade de se expressar plenamente. Na Hextr\u00EDade, acreditamos que a busca pela criatividade \u00E9 um caminho que envolve influ\u00EAncias tanto internas quanto externas. Estamos aqui para ajud\u00E1-lo em seu crescimento pessoal e profissional, auxiliando-o a utilizar todo o seu potencial criativo em prol do seu desenvolvimento."))))));
});
CarrosselCard.displayName = 'CarrosselCard';
export default CarrosselCard;
