import { Box, DataTable, Heading, ResponsiveContext, } from 'grommet';
import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { DocumentPerformance, Star, Upload } from 'grommet-icons';
import PageWrapper from '../PageWrapper/PageWrapper';
import InviteButton, { ImageTableHEX, TableButton } from './Styles/StyledComponents';
const HexpassTable = withTranslation()((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = props;
    const size = useContext(ResponsiveContext);
    const columns = [
        {
            property: 'Consultor',
            header: (React.createElement(ImageTableHEX, { src: "/public/pessoas.png" })),
            primary: true,
        },
        {
            property: 'Email',
            header: (React.createElement(ImageTableHEX, { src: "/public/email.png" })),
        },
        {
            property: 'Estrela',
            header: (React.createElement(ImageTableHEX, { src: "/public/estrela.png" })),
            size: 'small',
        },
        {
            property: 'Icone',
            header: (React.createElement(ImageTableHEX, { src: "/public/hexSimbolo.png" })),
            size: 'small',
        },
        {
            property: 'RelatoryButton',
            header: '',
            size: (size === 'xsmall' ? '22%' : '15%'),
        },
    ];
    const data = [
        {
            Consultor: 'Ricardo Amorim Gaefke',
            Email: 'ricardogaefke@gmail.com',
            Estrela: 'Avançada',
            Icone: React.createElement(Star, { color: "red", size: size === 'xsmall' ? '18px' : 'medium' }),
            RelatoryButton: (React.createElement(TableButton, { icon: (React.createElement(DocumentPerformance, { size: size === 'xsmall' ? '18px' : 'medium' })), label: "ENVIAR RELAT\u00D3RIO" })),
        },
        {
            Consultor: 'Bruno Amorim Gaefke',
            Email: 'brunogaefke@gmail.com',
            Estrela: 'Avançada',
            Icone: React.createElement(Star, { color: "red", size: size === 'xsmall' ? '18px' : 'medium' }),
            RelatoryButton: (React.createElement(TableButton, { icon: (React.createElement(DocumentPerformance, { size: size === 'xsmall' ? '18px' : 'medium' })), label: "ENVIAR RELAT\u00D3RIO" })),
        },
        {
            Consultor: 'Paulo Gaefke',
            Email: 'paulogaefke@gmail.com',
            Estrela: 'Avançada',
            Icone: React.createElement(Star, { color: "red", size: size === 'xsmall' ? '18px' : 'medium' }),
            RelatoryButton: (React.createElement(TableButton, { icon: (React.createElement(DocumentPerformance, { size: size === 'xsmall' ? '18px' : 'medium' })), label: "ENVIAR RELAT\u00D3RIO" })),
        },
    ];
    return (React.createElement(PageWrapper, null,
        React.createElement(Box, { align: "center", direction: "row", justify: "between", pad: size === 'xsmall' ? '0' : '0 10% 1%', fill: true },
            React.createElement(Heading, { size: size === 'xsmall' ? 'medium' : 'large', level: "3", color: "#8F8F8F", margin: "1rem" }, "HEXPASS DO PROJETO"),
            React.createElement(InviteButton, { icon: (React.createElement(Upload, { color: "#fff", size: size === 'xsmall' ? '18px' : 'medium' })), label: "ENVIAR AVALIA\u00C7\u00C3O", primary: true })),
        React.createElement(Box, { align: "center", justify: "center", fill: true, overflow: "auto" },
            React.createElement(DataTable, { columns: columns, data: data, step: 10, pad: { horizontal: 'small', vertical: 'xsmall' }, background: {
                    header: { color: 'dark-1', opacity: 'strong' },
                    body: ['light-1', 'light-3'],
                    footer: { color: 'dark-1', opacity: 'strong' },
                } }))));
});
HexpassTable.displayName = 'HexpassTable';
export default HexpassTable;
