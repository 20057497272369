import { Anchor, Box, Button, CheckBox, FormField, Text, TextInput, } from 'grommet';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Save } from 'grommet-icons';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import iniciaRecebimentoInitialValues from './Helpers/iniciaRecebimentoInitialValues';
import FormWrapper from '../../../../../FormWrapper/FormWrapper';
import confereEmailPreexistente from './Helpers/verificaSeConsultorExiste';
import RegExpForEmailValidation from '../../../../../../Utils/fieldValidation';
const IniciaRecebimentoForm = withTranslation()((props) => {
    const { email } = props;
    const history = useHistory();
    const { guid } = useParams();
    const navegarParaPrivacidade = () => { history.push('/privacidade'); };
    const [values, setValues] = useState(iniciaRecebimentoInitialValues(email, guid));
    const [submitting, setSubmitting] = useState(false);
    const onChange = (newValues) => {
        setValues(newValues);
    };
    const submit = async (event) => {
        const { value } = event;
        setSubmitting(true);
        await confereEmailPreexistente(value)
            .then((data) => {
            if (data.Success) {
                if (data.Object) {
                    toast.success('Chaves recebidas com sucesso');
                    history.push('/identity');
                }
                else {
                    toast.success('Vamos criar uma conta 😉');
                    history.push(`/chaves/criarContaDeConsultor/${guid}`);
                }
            }
            else {
                toast.error('Desculpe, mas não foi possível verificar o email');
            }
        })
            .catch(() => {
            toast.error('Desculpe, mas não foi possível verificar o email');
        });
    };
    return (React.createElement(Box, { width: "large", margin: {
            vertical: 'medium',
        } },
        React.createElement(FormWrapper, { values: values, onChange: onChange, onSubmit: submit },
            React.createElement(Box, { direction: "column", justify: "between", gap: "small", responsive: true },
                React.createElement(FormField, { label: "E-mail", name: "Email", width: "100%", required: true, validate: {
                        regexp: RegExpForEmailValidation,
                        message: 'E-mail inválido',
                    } },
                    React.createElement(TextInput, { name: "Email", type: "email", "aria-label": "Por favor informe o e-mail", title: "Por favor informe o email", placeholder: "email@email.com", maxLength: 95 })),
                React.createElement(FormField, { name: "AceitaTermos", width: "100%", required: true },
                    React.createElement(CheckBox, { name: "AceitaTermos", label: (React.createElement(Text, null,
                            "Aceito os",
                            ' ',
                            React.createElement(Anchor, { title: "Pol\u00EDtica de privacidade", onClick: navegarParaPrivacidade, label: "Termos de Privacidade" }))) }))),
            React.createElement(Box, { direction: "row", justify: "between", margin: { top: 'small' } },
                React.createElement(Button, { icon: React.createElement(Save, null), type: "submit", label: "Receber chaves", title: "Clique aqui para receber as chaves", primary: true, reverse: true, disabled: submitting })))));
});
IniciaRecebimentoForm.displayName = 'IniciaRecebimentoForm';
export default IniciaRecebimentoForm;
